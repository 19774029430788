import { Directive, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CLINIC_CONFIG_THEME_SERVICES, ClinicConfigThemeService } from '../classes/clinic-config-theme-service';
import { ClinicConfig } from '../models/clinic-config';
import { ClinicConfigService } from '../services/clinic-config.service';

@Directive({
  selector: '[svClinicConfigThemeOutlet]'
})
export class ClinicConfigThemeOutletDirective implements OnInit, OnDestroy {
  private readonly _destroySubject = new Subject<void>();

  public constructor(@Inject(CLINIC_CONFIG_THEME_SERVICES)
                     private configThemeServices: ClinicConfigThemeService[],
                     private configService: ClinicConfigService) {
  }

  private get onDestroy$(): Observable<void> {
    return this._destroySubject.asObservable();
  }

  public ngOnInit(): void {
    this.configService.clinicConfig$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((config: ClinicConfig | null) => this.applyThemeConfig(config));
  }

  public ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }

  private applyThemeConfig(config: ClinicConfig | null): void {
    for (const themeService of this.configThemeServices) {
      const styles = themeService.applyThemeConfig(config);

      for (const style of styles) {
        if (!style) continue;

        if (style?.value === undefined) {
          document.documentElement.style.removeProperty(style.name);
        } else {
          document.documentElement.style.setProperty(style.name, style.value);
        }
      }
    }
  }
}
