<div class="container" fxLayout="column" fxLayoutAlign="center center">

  <div (@fade.done)="onAnimationEnd($event)"
       (@fade.start)="onAnimationStart($event)"
       [@fade]="animationState"
       class="content"
       fxLayout="row"
       fxLayoutAlign="center center">

    <div [inlineSVG]="imageUrl"></div>
  </div>

</div>
