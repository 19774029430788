import { Selector } from '@ngxs/store';
import { WidgetPosition } from '@shareview/sessionmanager-api-client';
import { CastLayout } from '../types';
import { CastingState } from './casting.state';
import { CastingStateModel } from './casting.state-model';

export class CastingSelectors {

  @Selector([CastingState])
  public static hasSession(state: CastingStateModel): boolean {
    return !!state.sessionId && state.sessionId.length > 0;
  }

  @Selector([CastingState])
  public static sessionId(state: CastingStateModel): string | null {
    return state.sessionId;
  }

  @Selector([CastingState])
  public static layout(state: CastingStateModel): CastLayout | null {
    return state.layout;
  }

  @Selector([CastingSelectors.layout])
  public static layoutClass(layout: CastLayout): string | null {
    return layout ? `layout-${layout}` : null;
  }

  @Selector([CastingState])
  public static widgets(state: CastingStateModel): WidgetPosition[] {
    let count = 0;

    switch (state.layout) {
      case '1':
        count = 1;
        break;

      case '2-horizontal':
      case '2-vertical':
        count = 2;
        break;

      case '3-bottom':
      case '3-left':
      case '3-right':
      case '3-top':
        count = 3;
        break;

      case '4':
        count = 4;
        break;
    }

    return state.widgets
      .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
      .slice(0, count);
  }
}
