import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { ConfigurationLoaderService } from '../../services';

function configurationInitFactory(configurationLoaderService: ConfigurationLoaderService): () => Promise<unknown> {
  return () => {
    return configurationLoaderService.loadConfiguration();
  };
}

export function provideConfigurationLoader(): EnvironmentProviders {
  return makeEnvironmentProviders([
    { provide: ConfigurationLoaderService },
    {
      provide: APP_INITIALIZER,
      useFactory: configurationInitFactory,
      deps: [ConfigurationLoaderService],
      multi: true
    }
  ]);
}
