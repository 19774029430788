import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { VideoLibraryPageComponent } from './pages';
import { VideoLibrary2PageComponent } from './pages/video-library-2-page/video-library-2-page.component';
import { VideoLibrary3PageComponent } from './pages/video-library-3-page/video-library-3-page.component';
import { VideoLibraryState } from './state';

export const videoLibraryRoutes: Routes = [
  // { path: '', loadComponent: () => VideoLibraryPageComponent }
  {
    path: '',
    loadComponent: () => VideoLibraryPageComponent,
    // loadComponent: () => VideoLibrary2PageComponent,
    // loadComponent: () => VideoLibrary3PageComponent,
    providers: [importProvidersFrom(NgxsModule.forFeature([VideoLibraryState]))]
  }
];
