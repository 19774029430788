import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { FocusableComponent } from '@shareview/shared/focusable';
import { VideoCardComponent } from '../video-card';

@Component({
  selector: 'sv-video-list-row',
  standalone: true,
  imports: [CommonModule, VideoCardComponent],
  templateUrl: './video-list-row.component.html',
  styleUrls: ['./video-list-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => VideoListRowComponent) }]
})
export class VideoListRowComponent extends FocusableComponent {
  @Input()
  public videos: string[] = [];

  // TODO TrackByFn

  public override handleKeyPress(event: KeyboardEvent): boolean {
    switch (event.key) {
      case 'ArrowLeft':
        this.decrementChildFocus();
        return true;

      case 'ArrowRight':
        this.incrementChildFocus();
        return true;
    }

    return false;
  }
}
