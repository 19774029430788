import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, Provider } from '@angular/core';
import { Store } from '@ngxs/store';
import { SERVICE_MEDIA_API_BASE_URL } from '@shareview/media-api-client';
import { SERVICE_VIDEOCALL_API_BASE_URL } from '@shareview/videocall-api-client';
import { EnvironmentSelectors } from '../../state';

function getServiceUrl(store: Store, serviceName: string): string | null {
  const config = store.selectSnapshot(EnvironmentSelectors.serviceUrl(serviceName));

  // if (!config) {
  //   throw new Error(`Could not load service url '${serviceName}' from environment configuration!`);
  // }

  return config;
}

function serviceUrlProvider(endpointToken: InjectionToken<unknown>, serviceName: string): Provider {
  return {
    provide: endpointToken,
    useFactory: (store: Store) => getServiceUrl(store, serviceName),
    deps: [Store]
  };
}

export function provideApiEndpointsFromEnvironment(): EnvironmentProviders {
  return makeEnvironmentProviders([
    serviceUrlProvider(SERVICE_MEDIA_API_BASE_URL, 'media'),
    serviceUrlProvider(SERVICE_VIDEOCALL_API_BASE_URL, 'video-call')
  ]);
}
