import { OverlayRef } from '@angular/cdk/overlay';
import { filter, Observable, Subject, take } from 'rxjs';
import { PlayerControlsComponent } from '../components/player-controls/player-controls.component';
import { VideoCaptionState, VideoPlaybackState, VideoPlayerControlAction } from '../types/player-controls.types';

export class PlayerControlsRef {
  private readonly _instance: PlayerControlsComponent;
  private _overlayRef: OverlayRef;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _autoCloseTimer?: any;

  private readonly _beforeClose = new Subject<void>();
  private readonly _afterClosed = new Subject<void>();

  public constructor(instance: PlayerControlsComponent, overlayRef: OverlayRef) {
    this._instance = instance;
    this._overlayRef = overlayRef;

    this._instance.animationStateChanged.pipe(
      filter(event => event.phaseName === 'done'),
      filter(event => event.toState === 'leave'),
      take(1)
    ).subscribe(() => {
      clearTimeout(this._autoCloseTimer);
      this._afterClosed.next();
      this._afterClosed.complete();

      this._overlayRef?.detach();
      this._overlayRef?.dispose();
    });

    this.resetTimeout();
  }

  public get instance(): PlayerControlsComponent {
    return this._instance;
  }

  public get beforeClose$(): Observable<void> {
    return this._beforeClose.asObservable();
  }

  public get afterClosed$(): Observable<void> {
    return this._afterClosed.asObservable();
  }

  public get playbackState(): VideoPlaybackState {
    return this._instance.playbackState ?? 'stopped';
  }

  public set playbackState(value: VideoPlaybackState) {
    if (this._instance) {
      this._instance.playbackState = value;
    }
  }

  public get duration(): number {
    return this._instance.duration ?? 0;
  }

  public set duration(value: number) {
    if (this._instance) {
      this._instance.duration = value;
    }
  }

  public get timestamp(): number {
    return this._instance.timestamp ?? 0;
  }

  public set timestamp(value: number) {
    this._instance.timestamp = value;
  }

  public get captionState(): VideoCaptionState {
    return this._instance.captionState ?? 'disabled';
  }

  public set captionState(value: VideoCaptionState) {
    this._instance.captionState = value;
  }

  public get action$(): Observable<VideoPlayerControlAction> {
    return this._instance.action;
  }

  public resetTimeout(): void {
    clearTimeout(this._autoCloseTimer);

    this._autoCloseTimer = setTimeout(() => this.close(), 3 * 1000);
  }

  public close(): void {
    clearTimeout(this._autoCloseTimer);

    this._instance.startExitAnimation();
  }
}
