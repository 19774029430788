import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { QrCodeModule } from 'ng-qrcode';

@Component({
  selector: 'sv-scan-page',
  standalone: true,
  imports: [CommonModule, QrCodeModule],
  templateUrl: './scan-page.component.html',
  styleUrls: ['./scan-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScanPageComponent {
}
