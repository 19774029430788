import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { BaseComponent } from '@shareview/shared/components';
import { ListStack } from '@shareview/shared/data-models';
import { Focusable, KeyPressHandler } from '../../interfaces';
import { FocusChangeDirection, FocusScrollOrientation } from '../../types';

@Component({
  template: ''
})
export abstract class FocusableComponent extends BaseComponent implements OnDestroy, Focusable, KeyPressHandler {
  @ViewChildren(FocusableComponent, { emitDistinctChangesOnly: true })
  private _focusableChildren!: QueryList<FocusableComponent>;

  @ViewChildren(FocusableComponent, { read: ElementRef, emitDistinctChangesOnly: true })
  private _focusableChildElements!: QueryList<ElementRef>;

  @Input()
  public focusUp?: FocusableComponent;
  @Input()
  public focusDown?: FocusableComponent;
  @Input()
  public focusLeft?: FocusableComponent;
  @Input()
  public focusRight?: FocusableComponent;

  @Output()
  public readonly childFocusChange = new EventEmitter<FocusableComponent | undefined>();

  protected changeDetectorRef: ChangeDetectorRef;
  protected focusScrollOrientation: FocusScrollOrientation = 'none';

  private _focus = false;
  private _focusStack = new ListStack<FocusableComponent>();

  public constructor() {
    super();

    this.changeDetectorRef = inject(ChangeDetectorRef);
  }

  public _currentFocusChildIndex = -1;

  public get focusableChildren(): QueryList<FocusableComponent> {
    return this._focusableChildren;
  }

  public get focusableChildElements(): QueryList<ElementRef> {
    return this._focusableChildElements;
  }

  public get currentFocusChildIndex(): number {
    return this._currentFocusChildIndex;
  }

  public set currentFocusChildIndex(value: number) {
    this.changeDetectorRef.detach();
    this.focusedChild?.setFocus(false);

    if (value < (this.focusableChildren?.length ?? 0)) {
      this._currentFocusChildIndex = value;
    } else {
      this._currentFocusChildIndex = (this.focusableChildren?.length ?? 0) - 1;
    }

    this.focusedChild?.setFocus(true);

    this.changeDetectorRef.reattach();
    this.changeDetectorRef.markForCheck();

    this.onFocusedItemChanged(this.focusedChild, this.focusedChildElement, this._currentFocusChildIndex);
    this.childFocusChange.emit(this.focusedChild);
  }

  public get isFocused(): boolean {
    return this._focus;
  }

  public get canFocus(): boolean {
    return true;
  }

  public get hasFocusableChildren(): boolean {
    return (this.focusableChildren?.length ?? 0) > 0;
  }

  public get canDecrementChildFocus(): boolean {
    if (this.currentFocusChildIndex < 0) {
      return false;
    }

    return this.currentFocusChildIndex > 0;
  }

  public get canIncrementChildFocus(): boolean {
    if (this.currentFocusChildIndex < 0) {
      return false;
    }

    return this.currentFocusChildIndex < this.focusableChildren.length - 1;
  }

  public get focusedChild(): FocusableComponent | undefined {
    return this.focusableChildren?.get(this.currentFocusChildIndex);
  }

  public get previousFocusChildElement(): ElementRef | undefined {
    return this.focusableChildElements.get(this.currentFocusChildIndex - 1);
  }

  public get focusedChildElement(): ElementRef | undefined {
    return this.focusableChildElements.get(this.currentFocusChildIndex);
  }

  public get nextFocusChildElement(): ElementRef | undefined {
    return this.focusableChildElements.get(this.currentFocusChildIndex + 1);
  }

  public setFocus(focused: boolean): void {
    this.changeDetectorRef.detach();

    this._focus = focused;

    if (this.hasFocusableChildren && this.currentFocusChildIndex < 0) {
      this.currentFocusChildIndex = 0;
    }

    this.focusedChild?.setFocus(focused);
    this.onFocusedItemChanged(this.focusedChild, this.focusedChildElement, this.currentFocusChildIndex);
    this.changeDetectorRef.reattach();
    this.changeDetectorRef.detectChanges();
  }

  public pushFocusableInterceptor(focusable: FocusableComponent): void {
    this._focusStack.push(focusable);
  }

  public popFocusableInterceptor(): void {
    this._focusStack.pop();
  }

  public decrementChildFocus(): void {
    if (!this.canDecrementChildFocus) {
      return;
    }

    this.currentFocusChildIndex--;
  }

  public incrementChildFocus(): void {
    if (!this.canIncrementChildFocus) {
      return;
    }

    this.currentFocusChildIndex++;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleKeyPress(event: KeyboardEvent): boolean {
    if (this.handleChildKeyPress(event)) {
      return true;
    }

    switch (event.key) {
      case 'ArrowUp': {
        if (this.focusScrollOrientation === 'vertical') {
          this.decrementChildFocus();

          return true;
        }

        return this.tryFocusTarget('up');
      }

      case 'ArrowDown': {
        if (this.focusScrollOrientation === 'vertical') {
          this.incrementChildFocus();

          return true;
        }

        return this.tryFocusTarget('down');
      }

      case 'ArrowLeft': {
        if (this.focusScrollOrientation === 'horizontal') {
          if (this.canDecrementChildFocus) {
            this.decrementChildFocus();

            return true;
          }
        }

        return this.tryFocusTarget('left');
      }

      case 'ArrowRight': {
        if (this.focusScrollOrientation === 'horizontal') {
          this.incrementChildFocus();

          return true;
        }

        return this.tryFocusTarget('right');
      }

      case 'Enter':
        return this.onFocusedItemSelected(this.focusedChild, this.focusedChildElement, this.currentFocusChildIndex);
    }

    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onFocusedItemChanged(component: FocusableComponent | undefined, element: ElementRef | undefined, index: number): void {
    // To be overridden by child component for custom functionality.
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onFocusedItemSelected(component: FocusableComponent | undefined, element: ElementRef | undefined, index: number): boolean {
    // To be overridden by child component for custom functionality.

    return false;
  }

  protected handleChildKeyPress(event: KeyboardEvent): boolean {
    if (this._focusStack.hasItems()) {
      return this._focusStack.peek()?.handleKeyPress(event) ?? true;
    }

    return this.focusedChild?.handleKeyPress(event) ?? false;
  }

  private tryFocusTarget(direction: FocusChangeDirection): boolean {
    const current = this.focusableChildren.get(this.currentFocusChildIndex);

    if (!current) {
      return false;
    }

    let target: FocusableComponent | undefined = undefined;

    switch (direction) {
      case 'up':
        target = current.focusUp;
        break;

      case 'down':
        target = current.focusDown;
        break;

      case 'left':
        target = current.focusLeft;
        break;

      case 'right':
        target = current.focusRight;
        break;
    }

    if (target?.canFocus !== true) {
      return false;
    }

    const index = this.focusableChildren.toArray().findIndex(component => component === target);

    if (index < 0) {
      return false;
    }

    this.currentFocusChildIndex = index;

    return true;
  }
}
