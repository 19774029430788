import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TimeService } from '@shareview/shared/services';
import { Observable } from 'rxjs';
import { DateTimeComponentStyle } from './date-time.component.style';

@Component({
  selector: 'sv-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeComponent extends DateTimeComponentStyle {

  public constructor(private timeService: TimeService) {
    super();
  }

  public get timestamp$(): Observable<Date> {
    return this.timeService.timestamp$;
  }

  public get timestampFormat(): string {
    return this.value?.dateTimeFormat ?? 'EEEE, MMMM d, yyyy';
  }
}
