import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { FocusableComponent } from '@shareview/shared/focusable';
import { VideoCaptionState, VideoPlaybackState, VideoPlayerControlAction } from '../../types/player-controls.types';

@Component({
  selector: 'sv-player-controls',
  templateUrl: './player-controls.component.html',
  styleUrls: ['./player-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => PlayerControlsComponent) }],
  animations: [
    trigger('slide', [
      state('void', style({ transform: 'translate3d(0, 25%, 0)', opacity: 0 })),
      state('enter', style({ transform: 'none', opacity: 1 })),
      state('leave', style({ transform: 'translate3d(0, 25%, 0)', opacity: 0 })),
      transition('* => *', animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)'))
    ])
  ]
})
export class PlayerControlsComponent extends FocusableComponent implements AfterViewInit, OnDestroy {
  @Output()
  public action = new EventEmitter<VideoPlayerControlAction>();

  @Output()
  public animationStateChanged = new EventEmitter<AnimationEvent>();

  public animationState: 'enter' | 'leave' = 'enter';

  private _playbackState: VideoPlaybackState = 'stopped';

  @Input()
  public get playbackState(): VideoPlaybackState {
    return this._playbackState;
  }

  public set playbackState(value: VideoPlaybackState) {
    this._playbackState = value;
    this.changeDetectorRef.markForCheck();
  }

  private _duration = 0;

  @Input()
  public get duration(): number {
    return this._duration;
  }

  public set duration(value: number) {
    this._duration = value;
    this.changeDetectorRef.markForCheck();
  }

  private _timestamp = 0;

  @Input()
  public get timestamp(): number {
    return this._timestamp;
  }

  public set timestamp(value: number) {
    this._timestamp = value;
    this.changeDetectorRef.markForCheck();
  }

  private _captionState: VideoCaptionState = 'disabled';

  @Input()
  public get captionState(): VideoCaptionState {
    return this._captionState;
  }

  public set captionState(value: VideoCaptionState) {
    this._captionState = value;
    this.changeDetectorRef.markForCheck();
  }

  public get progress(): number {
    if (this._duration === 0) {
      return 0;
    }

    return this._timestamp / this._duration * 100;
  }

  public ngAfterViewInit(): void {
    this.setFocus(true);
  }

  public override ngOnDestroy(): void {
    this.action.complete();
    this.animationStateChanged.complete();

    super.ngOnDestroy();
  }

  public override handleKeyPress(event: KeyboardEvent): boolean {
    switch (event.key) {
      // case 'Escape':
      // case 'XF86Back':
      //   this.startExitAnimation();
      //   return true;

      case 'ArrowLeft':
        this.decrementChildFocus();
        return true;

      case 'ArrowRight':
        this.incrementChildFocus();
        return true;

      case 'Enter':
      case ' ':
        this.focusableChildElements.get(this.currentFocusChildIndex)?.nativeElement?.click();
        return true;

      default:
        return false;
    }
  }

  public onAnimationStart(event: AnimationEvent): void {
    this.animationStateChanged.emit(event);
  }

  public onAnimationEnd(event: AnimationEvent): void {
    this.animationStateChanged.emit(event);
  }

  public startExitAnimation(): void {
    this.animationState = 'leave';

    this.changeDetectorRef.detectChanges();
  }

  public playClicked(): void {
    this.action.emit('play');
  }

  public rewindClicked(): void {
    this.action.emit('rewind');
  }

  public fastForwardClicked(): void {
    this.action.emit('fast_forward');
  }

  public subtitleClicked(): void {
    this.action.emit('caption');
  }
}

