import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input } from '@angular/core';
import { FocusableComponent } from '@shareview/shared/focusable';
import { VideoListRowComponent } from '../video-list-row/video-list-row.component';

@Component({
  selector: 'sv-video-list',
  standalone: true,
  imports: [CommonModule, VideoListRowComponent],
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => VideoListComponent) }]
})
export class VideoListComponent extends FocusableComponent {
  public constructor(private elementRef: ElementRef) {
    super();
  }

  private _videos: string[][] = [];

  @Input()
  public get videos(): string[] {
    return this._videos.reduce((arr, curr) => [...arr, ...curr]);
  }

  public set videos(value: string[]) {
    this._videos = [];

    while (value.length) {
      this._videos.push(value.splice(0, 3));
    }
  }

  protected get videoRows(): string[][] {
    return this._videos;
  }

  // TODO TrackByFn

  public override handleKeyPress(event: KeyboardEvent): boolean {
    if (this.handleChildKeyPress(event)) {
      return true;
    }

    switch (event.key) {
      case 'ArrowUp': {
        const offset = this.focusedChild?.currentFocusChildIndex ?? 0;
        const sT = this.elementRef.nativeElement.scrollTop;
        const pT = this.previousFocusChildElement?.nativeElement.offsetTop;

        if (pT < sT)
          this.previousFocusChildElement?.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest'
          });

        this.decrementChildFocus();

        if (this.focusedChild) {
          this.focusedChild.currentFocusChildIndex = offset;
        }

        return true;
      }

      case 'ArrowDown': {
        const offset = this.focusedChild?.currentFocusChildIndex ?? 0;
        const vH = this.elementRef.nativeElement.offsetHeight;
        const nT = this.nextFocusChildElement?.nativeElement.offsetTop ?? 0;
        const nH = this.nextFocusChildElement?.nativeElement.offsetHeight ?? 0;

        // TODO Try using ng smooth scroll thingy...
        if (nT + nH > vH)
          this.elementRef.nativeElement.scrollTo(0, this.elementRef.nativeElement.scrollTop + nH);
        // this.nextFocusChildElement?.nativeElement.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'nearest',
        //   inline: 'nearest'
        // });

        this.incrementChildFocus();

        if (this.focusedChild) {
          this.focusedChild.currentFocusChildIndex = offset;
        }

        return true;
      }
    }

    return false;
  }
}
