import { Queue } from '../interfaces';

export class ListQueue<T> implements Queue<T> {
  private readonly _items: T[] = [];

  public constructor(private capacity: number = Infinity) {
  }

  public enqueue(item: T): void {
    if (this.size() === this.capacity) {
      throw Error('Queue has reached max capacity');
    }

    this._items.push(item);
  }

  public dequeue(): T | undefined {
    return this._items.shift();
  }

  public size(): number {
    return this._items.length;
  }
}
