import { EnvironmentConfiguration } from '../../models';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EnvironmentActions {
  const NAMESPACE = '[Environment]';

  export class LoadEnvironments {
    public static readonly type = `${NAMESPACE} Load Environments`;

    public constructor(public config: EnvironmentConfiguration) {
    }
  }

  export class ChangeEnvironment {
    public static readonly type = `${NAMESPACE} Change Environment`;

    public constructor(public environmentId: string) {
    }
  }

  export class Reset {
    public static readonly type = `${NAMESPACE} Reset`;
  }
}
