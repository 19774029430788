import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'dateTimeFormat',
  pure: false,
  standalone: true
})
export class DateTimeFormatPipe implements PipeTransform {

  public transform(timestamp: Date | null | undefined, format: string): string {
    if (!timestamp) {
      return '';
    }

    return dayjs(timestamp).format(format);
  }
}
