import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import { EnvironmentConfiguration } from '../../models';
import { EnvironmentActions } from '../../state/environment';

@Injectable()
export class ConfigurationLoaderService {

  public constructor(private httpClient: HttpClient,
                     private store: Store) {
  }

  public async loadConfiguration(): Promise<void> {
    const environments$ = this.httpClient.get<EnvironmentConfiguration>('assets/environment.json');
    const config = await firstValueFrom(environments$);

    this.store.dispatch(new EnvironmentActions.LoadEnvironments(config));
  }
}
