import { Environment } from '../../models';

export interface EnvironmentStateModel {
  defaultEnvironmentId: string | null;
  currentEnvironmentId: string | null;
  environments: Environment[];
}

export const DEFAULT_STATE: EnvironmentStateModel = {
  defaultEnvironmentId: null,
  currentEnvironmentId: null,
  environments: []
};
