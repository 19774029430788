// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DeviceActions {
  const NAMESPACE = '[Device]';

  export class SetClinicId {
    public static readonly type = `${NAMESPACE} Set Clinic ID`;

    public constructor(public clinicId: string) {
    }
  }

  export class SetDeviceId {
    public static readonly type = `${NAMESPACE} Set Device ID`;

    public constructor(public deviceId: string) {
    }
  }

  export class Reset {
    public static readonly type = `${NAMESPACE} Reset`;
  }
}
