import { ContentChildren, Directive, ElementRef, HostBinding, QueryList } from '@angular/core';
import { FocusableComponent } from '../components/focusable/focusable.component';

@Directive({
  selector: '[svScrollableViewport]'
})
export class ScrollableViewportDirective {
  @ContentChildren(FocusableComponent)
  public focusableChildren!: QueryList<FocusableComponent>;

  @ContentChildren(FocusableComponent, { read: ElementRef<FocusableComponent> })
  public focusableChildrenElements!: QueryList<ElementRef<FocusableComponent>>;

  @HostBinding('class')
  private elementClass = 'contents';
}
