import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  Input,
  QueryList
} from '@angular/core';
import { ScrollableViewportDirective } from '../../directives';
import { FocusableComponent } from '../focusable';

@Component({
  selector: 'sv-scrollable-focusable',
  templateUrl: './scrollable-focusable.component.html',
  styleUrls: ['./scrollable-focusable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => ScrollableFocusableComponent) }]
})
export class ScrollableFocusableComponent extends FocusableComponent {
  @ContentChild(ScrollableViewportDirective, { static: true })
  public viewport!: ScrollableViewportDirective;

  @ContentChild(ScrollableViewportDirective, { static: true, read: ElementRef })
  public viewportElement!: ElementRef;

  @Input()
  public disabled = false;

  public constructor(private elementRef: ElementRef) {
    super();
  }


  public override get focusableChildren(): QueryList<FocusableComponent> {
    return this.viewport.focusableChildren;
  }


  public override get focusableChildElements(): QueryList<ElementRef> {
    return this.viewport.focusableChildrenElements;
  }

  public override get hasFocusableChildren(): boolean {
    return this.viewport.focusableChildren.length > 0;
  }

  public override get canIncrementChildFocus(): boolean {
    if (this.currentFocusChildIndex < 0) {
      return false;
    }

    return this.currentFocusChildIndex < this.viewport.focusableChildren.length - 1;
  }

  public override get focusedChild(): FocusableComponent | undefined {
    return this.viewport.focusableChildren.get(this.currentFocusChildIndex);
  }

  public get canScrollBack(): boolean {
    const scrollLeft = this.elementRef.nativeElement.scrollLeft;

    return scrollLeft > 0;
  }

  public get canScrollForward(): boolean {

    const scrollLeft = this.elementRef.nativeElement.scrollLeft;
    const containerWidth = this.elementRef.nativeElement.offsetWidth;
    const viewportWidth = this.viewportElement.nativeElement.offsetWidth;

    return scrollLeft < viewportWidth - containerWidth;
  }

  private get previousElement(): ElementRef<FocusableComponent> | undefined {
    return this.viewport?.focusableChildrenElements?.get(this.currentFocusChildIndex - 1);
  }

  private get nextElement(): ElementRef<FocusableComponent> | undefined {
    return this.viewport?.focusableChildrenElements?.get(this.currentFocusChildIndex + 1);
  }

  public override handleKeyPress(event: KeyboardEvent): boolean {
    if (this.disabled) {
      return true;
    }

    if (this.handleChildKeyPress(event)) {
      return true;
    }

    switch (event.key) {
      case 'ArrowLeft':
        if (this.canDecrementChildFocus) {
          this.scrollLeft();
          this.decrementChildFocus();

          return true;
        }
        return false;

      case 'ArrowRight':
        if (this.canIncrementChildFocus) {
          this.scrollRight();
          this.incrementChildFocus();

          return true;
        }
        return false;

      case 'Escape':
      case 'XF86Back':
        this.currentFocusChildIndex = 0;
        this.elementRef.nativeElement.scrollLeft = 0;

        return false;

      default:
        return false;
    }
  }

  protected override onFocusedItemChanged(component: FocusableComponent | undefined, element: ElementRef | undefined, index: number): void {
    // TODO Fix this to replace scrollLeft() & scrollRight() methods!!!
    // if (!component || !element) {
    //   super.onFocusedItemChanged(component, element, index);
    //   return;
    // }
    //
    // const parentScrollLeft = this.elementRef.nativeElement.scrollLeft;
    // const overflowEnd = element.nativeElement.offsetLeft + element.nativeElement.offsetWidth - this.elementRef.nativeElement.offsetLeft - parentScrollLeft > this.elementRef.nativeElement.offsetWidth;
    // const overflowStart = element.nativeElement.offsetLeft - this.elementRef.nativeElement.offsetLeft - parentScrollLeft < 0;
    //
    // if (overflowEnd) {
    //   this.elementRef.nativeElement.scroll({ left: element.nativeElement.offsetLeft + element.nativeElement.offsetWidth - this.elementRef.nativeElement.offsetWidth, behavior: 'smooth' });
    // } else if (overflowStart) {
    //   this.elementRef.nativeElement.scroll({ left: element.nativeElement.offsetLeft, behavior: 'smooth' })
    // }
    //
    // super.onFocusedItemChanged(component, element, index);
  }

  private scrollLeft(): void {
    if (this.previousElement) {
      const previousElementRef = this.previousElement as ElementRef;
      const previousLeft = previousElementRef.nativeElement.offsetLeft;
      const previousWidth = previousElementRef.nativeElement.offsetWidth;
      const scrollLeft = this.elementRef.nativeElement.scrollLeft;

      if (previousLeft - (previousWidth / 2) - scrollLeft < scrollLeft)
        this.elementRef.nativeElement.scrollLeft = previousElementRef.nativeElement.offsetLeft - previousElementRef.nativeElement.offsetWidth - 10;

      if (this.elementRef.nativeElement.scrollLeft < (previousWidth / 2)) {
        this.elementRef.nativeElement.scrollLeft = 0;
      }
    }
  }

  private scrollRight(): void {
    if (this.nextElement) {
      const nextElementRef = this.nextElement as ElementRef;
      const viewportWidth = this.elementRef.nativeElement.offsetWidth;
      const viewportLeft = this.elementRef.nativeElement.offsetLeft;
      const nextLeft = nextElementRef.nativeElement.offsetLeft;
      const nextWidth = nextElementRef.nativeElement.offsetWidth;
      const scrollLeft = this.elementRef.nativeElement.scrollLeft;

      if (nextLeft + (nextWidth / 2) - scrollLeft - (viewportLeft / 2) > viewportWidth)
        this.elementRef.nativeElement.scrollLeft = nextLeft + nextWidth - viewportWidth;
    }
  }
}
