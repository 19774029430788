import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { LogLevels } from './log-level';

@Injectable()
export class LoggingService {

  public constructor(private applicationInsights: ApplicationInsights) {
  }

  public logError(clinicId: string, tag: string, event: unknown): void {
    this.applicationInsights.trackEvent({
      name: `ShareView Event - ${tag}`,
      properties: {
        clinicId: clinicId,
        userAgent: navigator.userAgent,
        timestamp: new Date(),
        tag: tag,
        level: LogLevels.Error,
        event: JSON.stringify(event)
      }
    });
  }
}
