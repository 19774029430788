import { Directive, HostBinding, Input } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { ClinicToolbarTextItem } from '../../interfaces';

@Directive()
export class TextComponentStyle {
  @HostBinding('style.color')
  private textColor?: SafeStyle;

  @HostBinding('style.font-size.px')
  private fontSize?: number;

  @HostBinding('style.font-weight')
  private fontWeight?: number;

  private _value?: ClinicToolbarTextItem;

  public get value(): ClinicToolbarTextItem | undefined {
    return this._value;
  }

  @Input()
  public set value(value: ClinicToolbarTextItem | undefined) {
    this._value = value;

    this.textColor = value?.textColor;
    this.fontSize = value?.fontSize;
    this.fontWeight = value?.fontWeight;
  }
}
