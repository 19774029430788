import { Injectable, InjectionToken } from '@angular/core';
import { ClinicConfig } from '../models/clinic-config';

export type CssStyleVar = { name: string, value: any };
export type StyleVars = (CssStyleVar | undefined)[];
export type CssStyleTransform = (value: any) => string;

export const CLINIC_CONFIG_THEME_SERVICES = new InjectionToken<ClinicConfigThemeService[]>('clinicConfigThemeServices');

@Injectable()
export abstract class ClinicConfigThemeService {

  public abstract applyThemeConfig(config: ClinicConfig | null): StyleVars;

  protected style(property: string, value?: unknown, ...transforms: CssStyleTransform[]): CssStyleVar | undefined {
    if (value === undefined) {
      return undefined;
    }

    return {
      name: property,
      value: transforms.length > 0 ? transforms.map(transform => transform(value)) : value
    };
  }
}
