<!--<div #wrapper class="content">-->
<!--  <ng-container *ngIf="videos.length > 0 && !loading else empty"></ng-container>-->
<!--  <ng-container *ngFor="let video of videos; trackBy: trackByVideoFn">-->
<!--    <sv-video-card [active]="isFocused" [style.width]="(wrapper.clientWidth - (32 * 3)) / 4 + 'px'"-->
<!--                   [video]="video"></sv-video-card>-->
<!--  </ng-container>-->
<!--</div>-->

<div #wrapper class="content" fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div [@indicator]="loaded"
         [class.hidden]="!(scroll.canScrollBack && loaded)"
         class="scroll-indicator"
         inlineSVG="/assets/arrow_back_ios_black_48dp.svg">
    </div>
  </div>

  <sv-scrollable-focusable #scroll  class="scroll" fxFlex>
    <div fxLayoutGap="20" fxFlex fxLayout="row" svScrollableViewport>
      <ng-container *ngFor="let item of items; index as i; trackBy: trackByVideoFn">
        <sv-video-card [active]="isFocused" [style.width]="(wrapper.clientWidth - (32 * 3)) / 4 + 'px'" [video]="item" (videoSelect)="onVideoSelected($event)"></sv-video-card>
      </ng-container>
    </div>
  </sv-scrollable-focusable>

  <div fxLayout="column" fxLayoutAlign="center center">
    <div [@indicator]="loaded"
         [class.hidden]="!(scroll.canScrollForward && loaded)"
         class="scroll-indicator"
         inlineSVG="assets/arrow_forward_ios_black_48dp.svg">
    </div>
  </div>
</div>

<ng-template #empty>
  <!-- TODO Figure this out... -->
  <!--  <div class="empty">-->
  <!--    <span><em>This category has no videos.</em></span>-->
  <!--  </div>-->
</ng-template>
