import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'progressTime',
  pure: true
})
export class ProgressTimePipe implements PipeTransform {

  private static decomposeTime(timestamp: number): number[] {
    const hours = Math.floor(timestamp / 3600);
    const minutes = Math.floor(timestamp / 60);
    const seconds = timestamp % 60;

    return [hours, minutes, seconds];
  }

  private static formatTimeParts(timeParts: number[]): string {
    return timeParts.reduce((acc: string, c: number, i: number) => {
      // Omit hours, if 0
      if (c < 1 && i < 1) {
        return acc;
      }

      const separator = acc.length > 0 ? ':' : '';
      const part = c.toFixed(0);
      const formatted = part.length < 2 && i > 0 ? `0${part}` : part;

      return `${acc}${separator}${formatted}`;
    }, '');
  }

  public transform(currentTime: number, totalTime: number): string {
    if (totalTime < 1) {
      return ' - / - ';
    }

    const current = ProgressTimePipe.decomposeTime(currentTime);
    const total = ProgressTimePipe.decomposeTime(totalTime);

    const currentFormatted = ProgressTimePipe.formatTimeParts(current);
    const totalFormatted = ProgressTimePipe.formatTimeParts(total);

    return `${currentFormatted} / ${totalFormatted}`;
  }
}
