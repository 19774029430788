import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { EnvironmentSelectors } from '@shareview/configuration';
import { WidgetPosition } from '@shareview/sessionmanager-api-client';
import { BaseComponent } from '@shareview/shared/components';
import { BehaviorSubject, catchError, combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';
import { CastingHubService } from '../../services';
import { CastingSelectors } from '../../state';
import { LoadingSpinnerComponent } from '../loading-spinner';

@Component({
  selector: 'sv-casting-content',
  standalone: true,
  imports: [CommonModule, LoadingSpinnerComponent],
  templateUrl: './casting-content.component.html',
  styleUrls: ['./casting-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturesCastingContentComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  @Select(EnvironmentSelectors.serviceUrl('session-manager'))
  private _baseUrl$!: Observable<string>;

  @Select(CastingSelectors.sessionId)
  private _sessionId$!: Observable<string | null>;
  private readonly _idSubject = new BehaviorSubject<number>(0);
  private readonly _imageBlobSubject = new BehaviorSubject<string | null>(null);

  public constructor(private castingHubService: CastingHubService,
                     private httpClient: HttpClient,
                     private store: Store) {
    super();
  }

  private _widget!: WidgetPosition;

  @Input()
  public get widget(): WidgetPosition {
    return this._widget;
  }

  public set widget(value: WidgetPosition) {
    this._widget = value;

    this._idSubject.next(value.position ?? 0);
  }

  protected get id$(): Observable<number> {
    return this._idSubject.asObservable();
  }

  protected get imageBlob$(): Observable<string | null> {
    return this._imageBlobSubject.asObservable();
  }

  public ngAfterViewInit(): void {
    this.subscription = combineLatest([this._baseUrl$, this._sessionId$, this.id$])
      .pipe(
        filter(([, sessionId]) => !!sessionId && sessionId.length > 0),
        map(([baseUrl, sessionId, widgetId]) => `${baseUrl}/v1/sessions/${sessionId}/widgets/${widgetId}`),
        switchMap(url => this.httpClient.get(url, { responseType: 'blob' })),
        map((blob) => URL.createObjectURL(blob)),
        catchError(error => {
          // TODO Do something with this to be more useful
          // TODO Maybe show error image?
          console.error(error);

          return of(null);
        })
      ).subscribe((image) => this._imageBlobSubject.next(image));
  }

  public override ngOnDestroy(): void {
    this._idSubject.complete();
    this._imageBlobSubject.complete();

    super.ngOnDestroy();
  }
}
