<sv-toolbar></sv-toolbar>

<div class="content flex-1">
  <div class="main">
    <div class="placeholder patient-info drop-shadow-lg">
      <h2>Welcome, James Connor</h2>
<!--      <h3>Room 3312</h3>-->
<!--      <h3>(555) 666-7777 ext. 1234</h3>-->
      <div class="flex-1"></div>

      <div class="sv-qr">
        <span class="drop-shadow-lg tk-proxima-nova-wide">ShareView</span>

        <ng-container *ngIf="sessionId$ | async as sessionId">
          <qr-code [darkColor]="'#FFFFFFFF'" [lightColor]="'#00000000'" [value]="sessionId" size="180"></qr-code>
        </ng-container>

        <span><em>For Clinicians Only</em></span>
      </div>
    </div>

    <div class="placeholder care-team drop-shadow-lg">
<!--      <div class="backdrop-opacity-10 backdrop-invert bg-white/30 patient">-->
<!--      </div>-->

      <h2>Your Care Team Today</h2>
      <table>
        <tr>
          <th>RN</th>
          <td>Melissa Nelson</td>
        </tr>

        <tr>
          <th>Care Tech</th>
          <td>David Smith</td>
        </tr>

        <tr>
          <th>Hospitalist</th>
          <td>Dr. Carolyn Johnson</td>
        </tr>

        <tr>
          <th>Unit Manager</th>
          <td>Jessica Brown - (342) 540-6859</td>
        </tr>

        <tr>
          <th>Goal</th>
          <td>Watch videos in <b>My Content</b> to start your discharge preparation.</td>
        </tr>
      </table>

      <div class="flex-1"></div>

      <h2>Your Room</h2>

      <table>
        <tr>
          <th>Room Number</th>
          <td>3312</td>
        </tr>

        <tr>
          <th>Phone Number</th>
          <td>(555) 666-7777 ext. 3312</td>
        </tr>
      </table>
    </div>

    <!--    <div class="placeholder qr-code drop-shadow-lg">-->
    <!--      <div class="drop-shadow-lg">-->
    <!--        <div class="drop-shadow-lg">-->
    <!--          <span class="drop-shadow-lg tk-proxima-nova-wide">ShareView</span>-->

    <!--          <ng-container *ngIf="sessionId$ | async as sessionId">-->
    <!--            <qr-code [darkColor]="'#FFFFFFFF'" [lightColor]="'#00000000'" [value]="sessionId" size="200"></qr-code>-->
    <!--          </ng-container>-->

    <!--          <span><em>For Clinicians Only</em></span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="placeholder ad-space drop-shadow-lg">
    </div>
  </div>

  <div class="categories">
    <sv-categories-buttons #categoryButtons
                           [focusDown]="videos"
                           [categories]="(categories$ | async) ?? []"
                           [selectedCategoryId]="categoryId$ | async"
                           (categorySelect)="updateCategoryVideos($event)">
    </sv-categories-buttons>
  </div>

  <sv-video-card-row #videos
                     class="library"
                     [focusUp]="categoryButtons"
                     [items]="(videos$ | async) ?? []"
                     (videoSelect)="videoSelected($event)">
  </sv-video-card-row>

</div>
