import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExperienceBrandFormatPipe } from './experience-brand-format.pipe';
import { ExperienceBrandType } from './experience-brand.type';

@Component({
  selector: 'sv-experience-brand',
  standalone: true,
  imports: [CommonModule, ExperienceBrandFormatPipe],
  templateUrl: './experience-brand.component.html',
  styleUrls: ['./experience-brand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceBrandComponent {
  @Input()
  public type!: ExperienceBrandType;
}
