export * from './lib/video-player-controls.module';
export * from './lib/classes/control-indicator.ref';
export * from './lib/classes/player-controls.ref';
export * from './lib/components/control-button/control-button.component';
export * from './lib/components/control-indicator/control-indicator.component';
export * from './lib/components/player-controls/player-controls.component';
export * from './lib/services/control-indicator.service';
export * from './lib/services/player-controls.service';
export * from './lib/types/control-indicator.types';
export * from './lib/types/player-controls.types';
