import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { VideoModel } from '@shareview/media-api-client';
import { FocusableComponent } from '@shareview/shared/focusable';

@Component({
  selector: 'sv-video-tile',
  templateUrl: './video-tile.component.html',
  styleUrls: ['./video-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => VideoTileComponent) }]
})
export class VideoTileComponent extends FocusableComponent {
  @Input()
  public item?: VideoModel;

  public get thumbnailUrl(): string | undefined {
    return this.item?.thumbnailUrl;
  }
}
