import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { VideoComponent } from './components/video/video.component';

@NgModule({
  declarations: [
    VideoComponent,
    VideoPlayerComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    FlexLayoutModule
  ],
  exports: [
    VideoComponent,
    VideoPlayerComponent
  ]
})
export class VideoPlayerModule {

  public static forRoot(): ModuleWithProviders<VideoPlayerModule> {
    return {
      ngModule: VideoPlayerModule,
      providers: []
    };
  }
}
