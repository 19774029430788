import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  InjectionToken,
  OnInit,
  ViewChild
} from '@angular/core';
import { FocusableComponent } from '@shareview/shared/focusable';
import { ControlIndicator, ControlIndicatorService, PlayerControlsService } from '@shareview/video/player-controls';
import { filter, map, takeUntil } from 'rxjs';
import { VideoMetadata } from '../../interfaces/video-metadata.interface';
import { VideoPlayerService } from '../../services/video-player.service';
import { VideoPlayerControlAction } from '../../types/video.types';
import { VideoComponent } from '../video/video.component';

export const VIDEO_DATA = new InjectionToken<VideoMetadata>('VIDEO_DATA');

@Component({
  selector: 'sv-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ControlIndicatorService,
    PlayerControlsService
  ]
})
export class VideoPlayerComponent extends FocusableComponent implements OnInit, AfterViewInit {
  @ViewChild(VideoComponent, { static: true })
  private _video!: VideoComponent;

  private _firstPlay = false;

  public constructor(@Inject(VIDEO_DATA)
                     public video: VideoMetadata,
                     // private clinicConfigService: ClinicConfigService,
                     private videoPlayerService: VideoPlayerService,
                     private controlIndicatorService: ControlIndicatorService,
                     private playerControlService: PlayerControlsService) {
    // private applicationInsights: ApplicationInsights) {
    super();
  }

  private get clinicId(): string {
    // return this.clinicConfigService.clinicConfig?.clinicId ?? 'unknown';
    return 'unknown';
  }

  public ngOnInit(): void {
    // Display an indicator popup for any actions performed by the user.
    this._video.playbackState$.pipe(
      map(state => state === 'playing' ? 'play' : 'pause' as ControlIndicator)
    ).subscribe(indicator => {
      // Don't show the indicator for the first play state after the video loads.
      if (!this._firstPlay || this._video.ended) {
        this._firstPlay = indicator === 'play';

        return;
      }

      this.controlIndicatorService.open(indicator);
    });

    // Sync playback state to the video controls.
    this._video.playbackState$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(state => this.playerControlService.playbackState = state);

    // Sync playback time code to the video controls.
    this._video.currentTime$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(time => this.playerControlService.timestamp = time);

    // Sync playback duration to the video controls.
    this._video.duration$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(duration => this.playerControlService.duration = duration);

    // Handle video player control actions.
    this.playerControlService
      .action$
      .subscribe((action: VideoPlayerControlAction) => {
        switch (action) {
          case 'play':
            this._video.playPause();
            break;

          case 'fast_forward':
            this.skipForward();
            break;

          case 'rewind':
            this.skipBack();
            break;
        }
      });
  }

  public ngAfterViewInit(): void {
    this._video.ended$.pipe(
      takeUntil(this.onDestroy$),
      filter(ended => ended === true)
    ).subscribe(() => this.closePlayer());

    this._video.loadVideo(this.video.videoUrl, this.video.mimeType);
  }

  public override handleKeyPress(event: KeyboardEvent): boolean {
    this.playerControlService.resetTimeout();

    if (this.handleChildKeyPress(event)) {
      return true;
    }

    switch (event.key) {
      case 'Escape':
      case 'XF86Back':
        this.closePlayer();
        return true;

      case 'Enter':
      case ' ':
        this._video.playPause();
        return true;

      case 'ArrowUp':
        this.openPlayerControls();
        return true;

      case 'ArrowDown':
        this.playerControlService.close();
        return true;

      case 'ArrowLeft':
        this.skipBack();
        return true;

      case 'ArrowRight':
        this.skipForward();
        return true;

      default:
        return false;
    }
  }

  @HostListener('document:mousemove')
  private openPlayerControls(): void {
    this.playerControlService.open(this, this._video.playbackState, this._video.currentTime, this._video.duration);
  }

  private skipForward(): void {
    this.controlIndicatorService.open('skip_forward');
    this._video.skipForward();
  }

  private skipBack(): void {
    this.controlIndicatorService.open('skip_back');
    this._video.skipBack();
  }

  private closePlayer(): void {
    const currentTime = this._video.currentTime;
    const durationTime = this._video.duration;
    const userAgent = navigator.userAgent;
    const timestamp = new Date();

    // this.applicationInsights.trackEvent({
    //   name: 'videoPlayed',
    //   properties: {
    //     clinicId: this.clinicId,
    //     userAgent: userAgent,
    //     videoUrl: this.video.videoUrl,
    //     timestamp: timestamp,
    //     playtime: currentTime,
    //     duration: durationTime
    //   }
    // });

    this.playerControlService.close();
    this._video.stop();
    this.videoPlayerService.close();
  }
}
