import { Stack } from '../interfaces/stack.interface';

export class ListStack<T> implements Stack<T> {
  private _items: T[] = [];

  public peek(): T | undefined {
    return this._items[this.size() - 1];
  }

  public pop(): T | undefined {
    return this._items.pop();
  }

  public push(item: T): void {
    this._items.push(item);
  }

  public size(): number {
    return this._items.length;
  }

  public hasItems(): boolean {
    return this.size() > 0;
  }
}
