import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { VideoPlayerComponent } from '../components/video-player/video-player.component';

export class VideoPlayerRef {
  private readonly _instance: VideoPlayerComponent;
  private readonly _overlayRef: OverlayRef;
  private readonly _beforeCloseSubject = new Subject<void>();
  private readonly _afterClosedSubject = new Subject<void>();

  public constructor(instance: VideoPlayerComponent, overlayRef: OverlayRef) {
    this._instance = instance;
    this._overlayRef = overlayRef;
  }

  public get beforeClose$(): Observable<void> {
    return this._beforeCloseSubject.asObservable();
  }

  public get afterClosed$(): Observable<void> {
    return this._afterClosedSubject.asObservable();
  }

  public get instance(): VideoPlayerComponent | undefined {
    return this._instance;
  }

  public close(): void {
    this._beforeCloseSubject.next();

    this._overlayRef.detach();
    this._overlayRef.detachBackdrop();
    this._overlayRef.dispose();

    this._afterClosedSubject.next();
    this._beforeCloseSubject.complete();
    this._afterClosedSubject.complete();
  }
}
