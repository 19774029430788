import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { VideoCallVonageComponent } from '@shareview/features/video-call';
import { WidgetPosition } from '@shareview/sessionmanager-api-client';
import { BaseComponent } from '@shareview/shared/components';
import { NgLetModule } from 'ng-let';
import { map, Observable, switchMap } from 'rxjs';
import { VideoCallWebRtcComponent } from '../../../../../video-call/src/lib/components/video-call-webrtc';
import { FeaturesCastingContentComponent, LoadingSpinnerComponent } from '../../components';
import { CastingHubService } from '../../services';
import { CastingActions, CastingSelectors } from '../../state';
import { CastLayout } from '../../types';

@Component({
  selector: 'sv-casting-page',
  standalone: true,
  imports: [CommonModule, LoadingSpinnerComponent, NgLetModule, FeaturesCastingContentComponent, VideoCallVonageComponent,VideoCallWebRtcComponent],
  templateUrl: './casting-page.component.html',
  styleUrls: ['./casting-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturesCastingPageComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Select(CastingSelectors.layoutClass)
  protected layoutClass$!: Observable<string | null>;

  @Select(CastingSelectors.widgets)
  protected widgets$!: Observable<WidgetPosition[]>;

  public constructor(private castingHubService: CastingHubService,
                     private route: ActivatedRoute,
                     private store: Store) {
    super();
  }

  public ngOnInit(): void {
    this.subscription = this.castingHubService.changeLayout$
      .pipe(
        // map(message => message.layout as CastLayout),
        map(message => new CastingActions.SetLayout(message.layout as CastLayout, message.widgets)),
        switchMap(action => this.store.dispatch(action))
      ).subscribe();
  }

  public async ngAfterViewInit(): Promise<void> {
    // const sessionId = this.store.selectSnapshot(CastingSelectors.sessionId);
    const sessionId = this.route.snapshot.paramMap.get('sessionId');

    if (sessionId) {
      await this.castingHubService.castingStarted(sessionId);
    }
  }
}
