import { Injectable } from '@angular/core';
import {
  ClinicConfig,
  ClinicConfigThemeService,
  ClinicConfigThemeTransforms,
  StyleVars
} from '@shareview/clinic/config';

@Injectable({
  providedIn: 'root'
})
export class VideoLibraryTileConfigThemeService extends ClinicConfigThemeService {

  public applyThemeConfig(config: ClinicConfig | null): StyleVars {
    const tileConfig = config?.home?.videoLibrary?.tiles;

    return [
      this.style('--clinic-video-library-tile-card-width', tileConfig?.cardWidth),

      this.style('--clinic-video-library-tile-background-color', tileConfig?.backgroundColor),
      this.style('--clinic-video-library-tile-select-color', tileConfig?.selectColor),
      this.style('--clinic-video-library-tile-select-size', tileConfig?.selectSize, ClinicConfigThemeTransforms.toPx),

      this.style('--clinic-video-library-tile-title-text-color', tileConfig?.titleTextColor),
      this.style('--clinic-video-library-tile-title-font-size', tileConfig?.titleFontSize, ClinicConfigThemeTransforms.toPx),
      this.style('--clinic-video-library-tile-title-font-weight', tileConfig?.titleFontWeight),

      this.style('--clinic-video-library-tile-caption-text-color', tileConfig?.captionTextColor),
      this.style('--clinic-video-library-tile-caption-font-size', tileConfig?.captionFontSize, ClinicConfigThemeTransforms.toPx),
      this.style('--clinic-video-library-tile-caption-font-weight', tileConfig?.captionFontWeight)
    ];
  }
}
