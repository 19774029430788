import { Pipe, PipeTransform } from '@angular/core';
import { VideoCategoryModel } from '@shareview/media-api-client';

@Pipe({
  name: 'categoryVideosLabel',
  pure: true
})
export class CategoryVideosLabelPipe implements PipeTransform {

  public transform(item?: VideoCategoryModel): string | null {
    if (item?.name) {
      return `${item.name} (${item.videos?.length ?? 0})`;
    }

    return null;
  }

}
