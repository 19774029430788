<div [class.selected]="isFocused" class="card" fxLayout="column">

  <div fxFlex fxLayout="row">

    <div class="card-inset" fxLayout="column">
      <div [url]="thumbnailUrl" class="thumbnail" fxFlex="66%" svBackgroundImage></div>

      <div fxFlex="25%" fxLayout="row">
        <div fxLayout="column">
          <span class="title">{{ item?.title }}</span>
          <span class="description">{{ item?.caption }}</span>
        </div>
      </div>
    </div>

  </div>

</div>
