<div svSize class="color-ring-2" [size]="ringSize2">
  <div svSize class="color-ring-1" [size]="ringSize1">
    <div svSize class="color-ring-0" [size]="ringSize0">

      <div class="aspect-square grid items-center justify-items-center">
        <div #content>
          <ng-content></ng-content>
        </div>
      </div>

    </div>
  </div>
</div>
