import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { delay, takeUntil } from 'rxjs';
import { FocusableComponent } from '../focusable/focusable.component';


@Component({
  template: ''
})
export abstract class FocusableViewChildrenComponent<TComponent, TData> extends FocusableComponent implements OnInit, AfterViewInit {
  @ViewChildren(FocusableComponent, { emitDistinctChangesOnly: true })
  public itemComponents!: QueryList<TComponent>;

  @Output()
  public readonly componentFocusChange = new EventEmitter<TComponent>();

  @Output()
  public readonly itemFocusChange = new EventEmitter<TData>();

  private _items: TData[] = [];

  @Input()
  public get items(): TData[] {
    return this._items;
  }

  public set items(value: TData[]) {
    this.changeDetectorRef.detach();

    if (this._items.length !== value.length) {
      // this._loaded = false;
      this._loaded = true;
    }

    this._items = value;

    this.changeDetectorRef.reattach();
    this.changeDetectorRef.detectChanges();
  }

  private _loaded = false;

  public get loaded(): boolean {
    return this._loaded && this._items.length > 0;
  }

  public get selectedItem(): TData | undefined {
    return this.items[this.currentFocusChildIndex];
  }

  protected get selectedComponent(): TComponent | undefined {
    return this.itemComponents.get(this.currentFocusChildIndex);
  }

  public ngOnInit(): void {
    this.childFocusChange.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.componentFocusChange.emit(this.selectedComponent);
      this.itemFocusChange.emit(this.selectedItem);
    });
  }

  public ngAfterViewInit(): void {
    this.itemComponents.changes.pipe(
      takeUntil(this.onDestroy$),
      delay(500)
    ).subscribe(() => {
      this._loaded = true;
      this.changeDetectorRef.detectChanges();
    });
  }
}
