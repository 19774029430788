import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { FocusableComponent } from '@shareview/shared/focusable';

@Component({
  selector: 'sv-category-button',
  standalone: true,
  imports: [CommonModule, MatRippleModule],
  templateUrl: './category-button.component.html',
  styleUrls: ['./category-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => CategoryButtonComponent) }]
})
export class CategoryButtonComponent extends FocusableComponent {
  @Input()
  public id = '';

  @Input()
  public name = '';

  @Input()
  public count = 0;

  @Input()
  public selected = false;

  @Input()
  public showCount = true;

  @Input()
  public active = false;

  protected get label(): string {
    if (this.showCount) {
      return `${this.name} (${this.count})`;
    } else {
      return this.name;
    }
  }
}
