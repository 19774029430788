import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SizeDirective } from '../directives';

@Component({
  selector: 'sv-color-rings-layout',
  standalone: true,
  imports: [CommonModule, SizeDirective],
  templateUrl: './color-rings-layout.component.html',
  styleUrls: ['./color-rings-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorRingsLayoutComponent {
  @Input()
  public centerSize = 280;

  @Input()
  public ringSize = 44;

  protected get ringSize0(): number {
    return this.calcRingSize(0);
  }

  protected get ringSize1(): number {
    return this.calcRingSize(1);
  }

  protected get ringSize2(): number {
    return this.calcRingSize(2);
  }

  private calcRingSize(level: number): number {
    return  this.centerSize + (level * this.ringSize);
  }
}
