// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OnboardingActions {
  const NAMESPACE = '[Onboarding]';

  export class SetRegistrationCode {
    public static readonly type = `${NAMESPACE} Set Registration Code`;

    public constructor(public code: string) {
    }
  }

  export class Reset {
    public static readonly type = `${NAMESPACE} Reset`;
  }
}
