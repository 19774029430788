import { Selector } from '@ngxs/store';
import { DeviceState } from './device.state';
import { DeviceStateModel } from './device.state-model';

export class DeviceSelectors {

  @Selector([DeviceState])
  public static deviceId(state: DeviceStateModel): string | null {
    return state.deviceId;
  }

  @Selector([DeviceSelectors.deviceId])
  public static hasDeviceId(deviceId: string | null): boolean {
    return !!deviceId && deviceId.length > 0;
  }
}
