import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToolbarComponent } from '../../components';
import { NavigationBarComponent } from '../../components/navigation-bar';

@Component({
  selector: 'shareview-home-page',
  standalone: true,
  imports: [CommonModule, ToolbarComponent, RouterOutlet, NavigationBarComponent],
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageComponent {
}
