<div class="fill" fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="32px">

  <div class="fill-vertical" fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="32px">
    <ng-container *ngIf="sessionId$ | async as sessionId">
      <sv-qr-code-card [config]="clinicQrCodeConfig" [url]="sessionId ?? undefined"></sv-qr-code-card>
    </ng-container>
    <!--        <sv-qr-code-card *ngIf="showPatientQrCode" [config]="patientQrCodeConfig"></sv-qr-code-card>-->
  </div>

  <div class="fill-vertical" fxFlex="42" fxLayout="column" fxLayoutAlign="center center">
    <div class="hero">
      <img [src]="heroThumbnailUrl" />
    </div>
  </div>

  <div class="fill-vertical" fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
    <!--      <div fxFlex style="height: 100%;width: 300px;color:white;background: rgba(255,255,255,0.25);border-radius: 16px" fxLayout="row" fxLayoutAlign="center center">Ad Placeholder 1</div>-->
    <!--      <div fxFlex style="height: 100%;width: 300px;color:white;background: rgba(255,255,255,0.25);border-radius: 16px" fxLayout="row" fxLayoutAlign="center center">Ad Placeholder 2</div>-->
    <sv-qr-code-card *ngIf="showPatientQrCode" [config]="patientQrCodeConfig"></sv-qr-code-card>
  </div>
</div>
