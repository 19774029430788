import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { Observable, Subject, take } from 'rxjs';
import { VideoPlayerRef } from '../classes/video-player.ref';
import { VIDEO_DATA, VideoPlayerComponent } from '../components/video-player/video-player.component';
import { VideoMetadata } from '../interfaces/video-metadata.interface';

@Injectable({
  providedIn: 'root'
})
export class VideoPlayerService {
  private _videoPlayerRef?: VideoPlayerRef;

  private readonly _openSubject = new Subject<void>();
  private readonly _closeSubject = new Subject<void>();

  public constructor(private overlay: Overlay) {
  }

  public get isOpened(): boolean {
    return !!this._videoPlayerRef;
  }

  public get onOpen$(): Observable<void> {
    return this._openSubject;
  }

  public get onClose$(): Observable<void> {
    return this._closeSubject.asObservable();
  }

  public open(video: VideoMetadata): VideoPlayerRef {
    const overlayRef = this.overlay.create({
      width: '100%',
      height: '100%',
      positionStrategy: this.overlay.position()
        .global()
        .top('0')
        .bottom('0')
        .left('0')
        .right('0'),
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });

    const injector = Injector.create({
      providers: [
        { provide: VIDEO_DATA, useValue: video }
      ]
    });

    const playerPortal = new ComponentPortal(VideoPlayerComponent, null, injector);
    const portalRef = overlayRef.attach(playerPortal);
    const playerRef = new VideoPlayerRef(portalRef.instance, overlayRef);

    this._videoPlayerRef = playerRef;

    this._openSubject.next();

    playerRef.beforeClose$
      .pipe(
        take(1)
      ).subscribe(() => this._videoPlayerRef = undefined);

    playerRef.afterClosed$
      .pipe(
        take(1)
      ).subscribe(() => this._closeSubject.next());

    return this._videoPlayerRef;
  }

  public close(): void {
    this._videoPlayerRef?.close();
  }
}
