import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlIndicator } from '../../types/control-indicator.types';

@Component({
  selector: 'sv-control-indicator',
  templateUrl: './control-indicator.component.html',
  styleUrls: ['./control-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0, transform: 'scale(0.5)' })),
      state('enter', style({ opacity: 1, transform: 'scale(1)' })),
      state('leave', style({ opacity: 0, transform: 'scale(1.5)' })),
      transition('* => *', animate('250ms cubic-bezier(0.25, 0.8, 0.25, 1)'))
    ])
  ]
})
export class ControlIndicatorComponent implements OnDestroy {
  @Input()
  public indicator?: ControlIndicator;

  @Output()
  public animationStateChanged = new EventEmitter<AnimationEvent>();

  public animationState: 'void' | 'enter' | 'leave' = 'void';

  public get imageUrl(): string {
    switch (this.indicator) {
      case 'play':
        return '/assets/play_arrow_FILL1_wght400_GRAD0_opsz48.svg';

      case 'pause':
        return '/assets/pause_FILL1_wght400_GRAD0_opsz48.svg';

      case 'skip_back':
        return '/assets/fast_rewind_FILL1_wght400_GRAD0_opsz48.svg';

      case 'skip_forward':
        return '/assets/fast_forward_FILL1_wght400_GRAD0_opsz48.svg';

      default:
        return '';
    }
  }

  public ngOnDestroy(): void {
    this.animationStateChanged.complete();
  }

  public onAnimationStart(event: AnimationEvent): void {
    this.animationStateChanged.emit(event);
  }

  public onAnimationEnd(event: AnimationEvent): void {
    this.animationStateChanged.emit(event);
  }

  public startEnterAnimation(): void {
    this.animationState = 'enter';
  }

  public startExitAnimation(): void {
    this.animationState = 'leave';
  }
}
