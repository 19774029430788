<ng-container *ngLet="configService.clinicConfig$ | async as config">
  <div svClinicConfigThemeOutlet class="fill" fxLayout="column" [@loading]="">
    <sv-clinic-toolbar [config]="config?.toolbar"></sv-clinic-toolbar>

    <div svBackgroundImage
         fxFlex
         class="content"
         fxLayout="column"
         fxLayoutAlign="center center"
         fxLayoutGap="32px"
         [url]="config?.home?.backgroundImageUrl">

      <sv-home-content fxFlex="60%"></sv-home-content>
      <sv-video-library fxFlex="40%"></sv-video-library>

    </div>
  </div>
</ng-container>
