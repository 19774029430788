import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { ClinicConfigService, ClinicHomeQrCodeConfig } from '@shareview/clinic/config';
import { CastingSelectors } from '@shareview/features/casting';
import { distinctUntilChanged, map, Observable } from 'rxjs';

@Component({
  selector: 'sv-home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeContentComponent {
  @Select(CastingSelectors.sessionId)
  protected sessionId$!: Observable<string | null>;

  public constructor(private configService: ClinicConfigService) {
  }

  public get showClinicQrCode$(): Observable<boolean> {
    return this.configService.clinicConfig$
      .pipe(
        map(cfg => !!cfg?.home?.clinicQrCode?.url),
        distinctUntilChanged()
      );
  }

  public get showClinicQrCode(): boolean {
    return !!this.configService.clinicConfig?.home?.clinicQrCode?.url;
  }

  public get showPatientQrCode(): boolean {
    return !!this.configService.clinicConfig?.home?.patientQrCode?.url;
  }

  public get clinicQrCodeConfig(): ClinicHomeQrCodeConfig | undefined {
    return this.configService.clinicConfig?.home?.clinicQrCode;
  }

  public get patientQrCodeConfig(): ClinicHomeQrCodeConfig | undefined {
    return this.configService.clinicConfig?.home?.patientQrCode;
  }

  public get heroThumbnailUrl(): string | undefined {
    return this.configService.clinicConfig?.home?.hero?.thumbnailUrl;
  }
}
