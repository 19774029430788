import { OverlayModule } from '@angular/cdk/overlay';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { ClinicApiModule } from '@shareview/clinic/api';
import { ClinicComponentsModule } from '@shareview/clinic/components';
import { ClinicConfigModule } from '@shareview/clinic/config';
import { ClinicServicesModule } from '@shareview/clinic/services';
import { ClinicToolbarModule } from '@shareview/clinic/toolbar';
import {
  DeviceState,
  EnvironmentState,
  provideApiEndpointsFromEnvironment,
  provideConfigurationLoader
} from '@shareview/configuration';
import { castingRoutes, CastingState } from '@shareview/features/casting';
import { homeRoutes } from '@shareview/features/home';
import { landingRoutes } from '@shareview/features/landing';
import { onboardingRoutes, OnboardingState } from '@shareview/features/onboarding';
import { videoLibraryRoutes } from '@shareview/features/video-library';
import { provideAuthenticationFromEnvironment, SharedAuthenticationModule } from '@shareview/shared/authentication';
import { SharedComponentsModule } from '@shareview/shared/components';
import { SharedServicesModule } from '@shareview/shared/services';
import { VideoPlayerModule } from '@shareview/video/player';
import { NgLetModule } from 'ng-let';
import { QrCodeModule } from 'ng-qrcode';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';
import { environment } from '../../../../../clinic-display/src/environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter([
      // { path: '', pathMatch: 'full', redirectTo: 'landing' },
      { path: '', pathMatch: 'full', redirectTo: 'video-library' },
      { path: 'cast', loadChildren: () => castingRoutes },
      { path: 'home', loadChildren: () => homeRoutes },
      { path: 'landing', loadChildren: () => landingRoutes },
      { path: 'onboarding', loadChildren: () => onboardingRoutes },
      { path: 'video-library', loadChildren: () => videoLibraryRoutes }
    ]),
    importProvidersFrom(
      NgxsModule.forRoot([
        EnvironmentState,
        DeviceState,
        CastingState,
        OnboardingState // TODO Move to onboarding route provider
        // VideoLibraryState
      ]),
      NgxsStoragePluginModule.forRoot({ storage: StorageOption.LocalStorage }),
      NgxsLoggerPluginModule.forRoot({ disabled: true }),
      NgxsReduxDevtoolsPluginModule.forRoot({ name: 'ShareView Cast - Tizen' })
    ),
    provideConfigurationLoader(),
    provideAuthenticationFromEnvironment(),
    provideApiEndpointsFromEnvironment(),
    importProvidersFrom(SharedAuthenticationModule.forRoot(),
      NgLetModule,
      OverlayModule,
      FlexLayoutModule,
      VideoPlayerModule.forRoot(),
      QrCodeModule,
      SharedComponentsModule,
      ClinicConfigModule,
      NgScrollbarModule,
      NgScrollbarReachedModule,

      ClinicComponentsModule.forRoot(),
      ClinicServicesModule.forRoot(),
      ClinicApiModule.forRoot({
        clinicId: environment.clinicId,
        refreshInterval: environment.refreshInterval,
        mediaApiUrl: environment.baseUrl
      }),
      SharedServicesModule.forRoot(),
      ClinicToolbarModule,
      VideoPlayerModule.forRoot(),
    )
  ]
};
