import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { ControlIndicatorRef } from '../classes/control-indicator.ref';
import { ControlIndicatorComponent } from '../components/control-indicator/control-indicator.component';
import { ControlIndicator } from '../types/control-indicator.types';

@Injectable()
export class ControlIndicatorService {

  public constructor(private overlay: Overlay) {
  }

  public open(indicator: ControlIndicator): void {
    const overlayRef = this.overlay.create({
      width: '128px',
      height: '128px',
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      panelClass: 'z-top'
    });

    const portal = new ComponentPortal(ControlIndicatorComponent);
    const componentRef = overlayRef.attach(portal);
    const controlRef = new ControlIndicatorRef(componentRef.instance, overlayRef);

    controlRef.indicator = indicator;

    controlRef.afterOpened$
      .pipe(
        take(1)
      ).subscribe(() => controlRef.close());

    controlRef.open();
  }
}
