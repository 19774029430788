import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[svSize]',
  standalone: true
})
export class SizeDirective {
  @HostBinding('style.width.px')
  private _width!: number;

  @HostBinding('style.height.px')
  private _height!: number;

  public get width(): number {
    return this._width;
  }

  @Input()
  public set width(value: number) {
    this._width = value;
  }

  public get height(): number {
    return this._height;
  }

  @Input()
  public set height(value: number) {
    this._height = value;
  }

  @Input()
  public set size(value: number) {
    this.width = value;
    this.height = value;
  }
}
