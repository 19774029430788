export type PermissionStatus = 'granted' | 'denied' | 'prompt' | 'error';
export type PermissionStatusCallback = (status: PermissionStatus) => void;

export type TrackMutedCallback = (muted: boolean) => void;

export class Camera {
  private _stream: MediaStream | null = null;
  private _root: HTMLDivElement | null = null;

  public onCameraPermissionStatus: PermissionStatusCallback = () => console.log(`Callback 'onCameraPermissionStatus' was not handled.`);
  public onMicrophonePermissionStatus: PermissionStatusCallback = () => console.log(`Callback 'onMicrophonePermissionStatus' was not handled.`);

  public onAudioMuted: TrackMutedCallback = muted => console.log(`Callback 'onAudioMuted(${muted})' was not handled.`);
  public onVideoMuted: TrackMutedCallback = muted => console.log(`Callback 'onVideoMuted(${muted})' was not handled.`)

  public get stream(): MediaStream {
    if (!this._stream) {
      throw new Error('Camera not connected');
    }

    return this._stream;
  }

  public get supportsPan(): boolean {
    return this.supportsConstraint('pan');
  }

  public get supportsTilt(): boolean {
    return this.supportsConstraint('tilt');
  }

  public get supportsZoom(): boolean {
    return this.supportsConstraint('zoom');
  }

  private supportsConstraint(constraint: string): boolean {
    try {
      const supports = navigator.mediaDevices.getSupportedConstraints();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return supports[constraint] ?? false;
    } catch (error) {
      console.log(error);

      return false;
    }
  }

  public get canPanLeft(): boolean {
    const result = this.getVideoTrackCapability('pan');

    if (!result) {
      return false;
    }

    return (result.setting - result.capability.step >= result.capability.min);
  }

  public get canPanRight(): boolean {
    const result = this.getVideoTrackCapability('pan');

    if (!result) {
      return false;
    }

    return (result.setting + result.capability.step <= result.capability.max);
  }

  public get canTiltUp(): boolean {
    const result = this.getVideoTrackCapability('tilt');

    if (!result) {
      return false;
    }

    return (result.setting + result.capability.step <= result.capability.max);
  }

  public get canTiltDown(): boolean {
    const result = this.getVideoTrackCapability('tilt');

    if (!result) {
      return false;
    }

    return (result.setting - result.capability.step >= result.capability.min);
  }

  public get canZoomOut(): boolean {
    const result = this.getVideoTrackCapability('zoom');

    if (!result) {
      return false;
    }

    return (result.setting - result.capability.step >= result.capability.min);
  }

  public get canZoomIn(): boolean {
    const result = this.getVideoTrackCapability('zoom');

    if (!result) {
      return false;
    }

    return (result.setting + result.capability.step <= result.capability.max);
  }

  public async panLeft(): Promise<boolean> {
    return this.applyCameraTrackCapabilityValue('pan', false);
  }

  public async panRight(): Promise<boolean> {
    return this.applyCameraTrackCapabilityValue('pan', true);
  }

  public async tiltUp(): Promise<boolean> {
    return this.applyCameraTrackCapabilityValue('tilt', true);
  }

  public async tiltDown(): Promise<boolean> {
    return this.applyCameraTrackCapabilityValue('tilt', false);
  }

  public async zoomIn(): Promise<boolean> {
    return this.applyCameraTrackCapabilityValue('zoom', true);
  }

  public async zoomOut(): Promise<boolean> {
    return this.applyCameraTrackCapabilityValue('zoom', false);
  }

  public muteAudio(): void {
    this._stream?.getAudioTracks()?.forEach(track => track.enabled = false);
    this.notifyOnAudioMutedCallback();
  }

  public unmuteAudio(): void {
    this._stream?.getAudioTracks()?.forEach(track => track.enabled = true);
    this.notifyOnAudioMutedCallback();
  }

  public toggleAudioMute(): void {
    this._stream?.getAudioTracks()?.forEach(track => track.enabled = !track.enabled);
    this.notifyOnAudioMutedCallback();
  }

  private notifyOnAudioMutedCallback(): void {
    const tracks = this._stream?.getAudioTracks();

    if (tracks && tracks.length > 0) {
      this.onAudioMuted(!tracks[0].enabled);
    }
  }

  public muteVideo(): void {
    this._stream?.getVideoTracks()?.forEach(track => track.enabled = false);
    this.notifyOnVideoMutedCallback();
  }

  public unmuteVideo(): void {
    this._stream?.getVideoTracks()?.forEach(track => track.enabled = true);
    this.notifyOnVideoMutedCallback();
  }

  public toggleVideoMute(): void {
    this._stream?.getVideoTracks()?.forEach(track => track.enabled = !track.enabled);
    this.notifyOnVideoMutedCallback();
  }

  private notifyOnVideoMutedCallback(): void {
    const tracks = this._stream?.getVideoTracks();

    if (tracks && tracks.length > 0) {
      this.onVideoMuted(!tracks[0].enabled);
    }
  }

  private async applyCameraTrackCapabilityValue(capabilityName: string, increase: boolean): Promise<boolean> {
    try {
      const tracks = this._stream?.getVideoTracks();

      if (!tracks || tracks.length < 1) {
        console.debug('canPanRight: no media tracks found.');

        return false;
      }

      const track = tracks[0];
      const capabilities = track.getCapabilities();
      const settings = track.getSettings();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const capability = capabilities[capabilityName];

      if (capability === undefined) {
        console.warn(`Camera ${capabilityName} should be supported, but was not available on the current media track capabilities.`, capabilities);

        return false;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const setting = settings[capabilityName];

      if (setting === undefined) {
        console.warn(`Camera ${capabilityName} should be supported, but was not available on the current media track settings.`, settings);

        return false;
      }

      const constraint: any = {
        [capabilityName]: setting + (capability.step * (increase ? 1 : -1))
      };

      console.debug('Applying camera track constraint:', constraint);

      await track.applyConstraints({ advanced: [constraint] });

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private getVideoTrackCapability(capabilityName: string): {
    capability: { min: number, max: number, step: number },
    setting: number
  } | null {
    try {
      const tracks = this._stream?.getVideoTracks();

      if (!tracks || tracks.length < 1) {
        console.debug('canPanRight: no media tracks found.');

        return null;
      }

      const track = tracks[0];
      const capabilities = track.getCapabilities();
      const settings = track.getSettings();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const capability = capabilities[capabilityName];

      if (capability === undefined) {
        console.warn(`Camera ${capabilityName} should be supported, but was not available on the current media track capabilities.`, capabilities);

        return null;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const setting = settings[capabilityName];

      if (setting === undefined) {
        console.warn(`Camera ${capabilityName} should be supported, but was not available on the current media track settings.`, settings);

        return null;
      }

      console.debug(`camera ${capabilityName} capabilities/settings:`, capability, setting);

      return { capability, setting };
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  public async checkCameraPermission(): Promise<PermissionStatus> {
    const panTiltZoom = this.supportsPan || this.supportsTilt || this.supportsZoom;
    const permission = await this.checkPermission('camera', panTiltZoom);

    console.debug('camera permission:', permission);
    this.onCameraPermissionStatus(permission);

    return permission;
  }

  public async checkMicrophonePermission(): Promise<PermissionStatus> {
    const permission = await this.checkPermission('microphone');

    console.debug('microphone permission:', permission);
    this.onMicrophonePermissionStatus(permission);

    return permission;
  }

  private async checkPermission(name: PermissionName | 'camera' | 'microphone', panTiltZoom?: boolean): Promise<PermissionStatus> {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const permission = await navigator.permissions.query({ name, panTiltZoom });

      return permission.state;
    } catch (error) {
      console.error(error);

      return 'error';
    }
  }

  public async connect(): Promise<MediaStream | null> {
    const cameraPermission = await this.checkCameraPermission();
    const microphonePermission = await this.checkMicrophonePermission();

    if (cameraPermission === 'denied' || cameraPermission === 'error') {
      return null;
    }

    if (microphonePermission === 'denied' || microphonePermission === 'error') {
      return null;
    }

    try {
      const constraints = {
        audio: {
          channelCount: { ideal: 1 },
          echoCancellation: { ideal: true }
        },
        video: {
          width: { max: 1920 },
          height: { max: 1080 },
          pan: this.supportsPan,
          tilt: this.supportsTilt,
          zoom: this.supportsZoom
        }
      };

      console.debug('Requested media constraints:', constraints);

      this._stream = await navigator.mediaDevices.getUserMedia(constraints);

      this.notifyOnAudioMutedCallback();
      this.notifyOnVideoMutedCallback();

      return this._stream;
    } catch (error) {
      console.error(error);

      await this.checkCameraPermission();
      await this.checkMicrophonePermission();

      return null;
    }
  }

  public disconnect(): void {
    if (this._stream) {
      this._stream.getTracks().forEach(track => {
        track.stop();
        this._stream?.removeTrack(track);
      });

      this._stream = null;
    }
  }

  public attach(element: HTMLDivElement): void {
    if (this._root) {
      console.warn('Self video already attached');
      return;
    }

    if (!this._stream) {
      console.error('Please connect camera first');
      return;
    }

    this._root = element;
    this._root.style.background = 'black';
    this._root.style.boxSizing = 'border-box';
    // this._root.style.position = 'relative';

    const video = Camera.createVideoElement(this.stream);

    this._root.appendChild(video);
  }

  public detach(): void {
    if (this._root) {
      this._root.childNodes.forEach(child => {
        this._root?.removeChild(child);
      });

      this._root = null;
    }
  }

  public async resize(width: number, height: number): Promise<MediaStream> {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        channelCount: { ideal: 1 },
        echoCancellation: { ideal: true }
      },
      video: {
        aspectRatio: width / height,
        width: { max: 1920 },
        height: { max: 1080 }
      }
    });

    const video = Camera.createVideoElement(stream);

    this._root?.childNodes.forEach(child => {
      this._root?.removeChild(child);
    });

    this._root?.appendChild(video);

    this._stream?.getTracks().forEach(track => track.stop());
    this._stream = stream;

    return this.stream;
  }

  private static createVideoElement(stream: MediaStream): HTMLVideoElement {
    const video = document.createElement('video');

    video.style.width = '100%';
    video.style.height = '100%';
    video.style.position = 'absolute';
    video.style.top = '0';
    video.style.bottom = '0';
    video.style.left = '0';
    video.style.right = '0';
    video.style.objectFit = 'cover';
    video.style.objectPosition = 'center center';
    video.style.borderRadius = '8px';
    video.autoplay = true;
    video.muted = true;
    video.srcObject = stream;

    return video;
  }
}
