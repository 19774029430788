import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faBellConcierge,
  faHandHoldingHeart,
  faHouse,
  faSunCloud,
  faTv,
  IconDefinition
} from '@fortawesome/pro-solid-svg-icons';

export interface NavigationButton {
  icon?: string;
  def?: IconDefinition;
  label: string;
}

@Component({
  selector: 'sv-navigation-bar',
  standalone: true,
  imports: [CommonModule, MatIconModule, NgOptimizedImage, FaIconComponent],
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationBarComponent {

  @Input()
  public buttons: NavigationButton[] = [
    // { icon: 'search', label: 'Search' },
    { def: faTv, icon: 'tv-solid-white', label: 'TV' },
    { def: faHouse, icon: 'house-solid-white', label: 'Home' },
    // { def: faHandHoldingHeart, icon: 'hand-holding-heart-solid-white', label: 'Wellness' },
    // { def: faBellConcierge, icon: 'bell-concierge-solid-white', label: 'Alerts' },
    // { def: faSunCloud, icon: 'sun-cloud-solid-white', label: 'Weather' }
  ];

  public faHouse = faHouse;
}
