import { v4 as uuidv4 } from 'uuid';
import { LayoutManager } from '../layout-manager';
import { Camera } from './camera';
import { ResizeMessage } from './peer2';
import { PeerManager } from './peer2-manager';
import { RtcMessage, SignalingService, UserHelloMessage, UserJoinMessage, UserLeaveMessage } from './signaling.service';

export class SessionManager {
  private readonly _peerId = uuidv4();
  public readonly camera = new Camera();
  public readonly layoutManager = new LayoutManager();
  public readonly peerManager = new PeerManager();
  private readonly signalingService = new SignalingService();

  public constructor(private channelId: string) {
    this.signalingService.onJoin = async ({ userId }: UserJoinMessage) => {
      await this.signalingService.hello(this.channelId, this._peerId, userId);

      const peer = this.peerManager.addPeer(userId);
      const layout = this.layoutManager.addPeer(userId);

      peer.addStream(this.camera.stream);
      layout.video.srcObject = peer.remoteStream;
    };

    this.signalingService.onHello = ({ fromUserId, toUserId }: UserHelloMessage) => {
      if (toUserId !== this._peerId) {
        return;
      }

      const peer = this.peerManager.addPeer(fromUserId);
      const layout = this.layoutManager.addPeer(fromUserId);

      peer.addStream(this.camera.stream);
      layout.video.srcObject = peer.remoteStream;
    };

    this.signalingService.onLeave = ({ userId }: UserLeaveMessage) => {
      this.layoutManager.removePeer(userId);
      this.peerManager.removePeer(userId, false);
    };

    this.signalingService.onRtc = async ({ candidate, description, fromUserId, toUserId }: RtcMessage) => {
      if (toUserId !== this._peerId) {
        return;
      }

      if (candidate) {
        await this.peerManager.getPeer(fromUserId)?.handleIce(JSON.parse(candidate));
      }

      if (description) {
        await this.peerManager.getPeer(fromUserId)?.handleDescription(JSON.parse(description));
      }
    };

    this.peerManager.onIce = async (peerId: string, event: RTCIceCandidate) => {
      await this.signalingService.rtc(this.channelId, this._peerId, peerId, event);
    };

    this.peerManager.onDescription = async (peerId: string, event: RTCSessionDescriptionInit | null) => {
      await this.signalingService.rtc(this.channelId, this._peerId, peerId, undefined, event);
    };

    this.peerManager.onEnd = (peerId: string) => {
      this.layoutManager.removePeer(peerId);
      this.peerManager.removePeer(peerId, false);
    };

    this.peerManager.onResize = async (peerId: string, { width, height }: ResizeMessage) => {
      // const stream = await this.camera.resize(width, height);
      //
      // this.peerManager.getPeer(peerId)?.replaceStream(stream);
    };

    this.peerManager.getContainerSize = (peerId: string) => {
      const layout = this.layoutManager.getPeerById(peerId);

      return {
        width: layout?.clientWidth ?? 1920 / 4,
        height: layout?.clientHeight ?? 1080 / 4
      };
    };

    this.peerManager.peerId = this._peerId;
  }

  public async connect(container: HTMLDivElement, self: HTMLDivElement): Promise<boolean> {
    this.layoutManager.attach(container);

    await this.signalingService.connect();

    const stream = await this.camera.connect();

    if (!stream) {
      await this.disconnect();

      return false;
    }

    this.camera.attach(self);

    await this.signalingService.join(this.channelId, this._peerId);

    return true;
  }

  public async disconnect(): Promise<void> {
    this.peerManager.peers.forEach(peer => {
      peer.end(false);
      this.peerManager.removePeer(peer.peerId, false);
    });

    await this.signalingService.disconnect();

    this.camera.detach();
    this.camera.disconnect();
    this.layoutManager.detach();
  }
}
