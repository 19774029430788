import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { VideoCategoryModel } from '@shareview/media-api-client';
import { FocusableComponent } from '@shareview/shared/focusable';

@Component({
  selector: 'sv-video-label',
  templateUrl: './video-label.component.html',
  styleUrls: ['./video-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => VideoLabelComponent) }]
})
export class VideoLabelComponent extends FocusableComponent {
  @Input()
  public active = false;

  @Input()
  public item?: VideoCategoryModel;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public override handleKeyPress(event: KeyboardEvent): boolean {
    return false;
  }
}
