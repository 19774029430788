import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { FocusableComponent } from '@shareview/shared/focusable';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { PlayerControlsRef } from '../classes/player-controls.ref';
import { PlayerControlsComponent } from '../components/player-controls/player-controls.component';
import { VideoPlaybackState, VideoPlayerControlAction } from '../types/player-controls.types';

@Injectable()
export class PlayerControlsService {
  private _instance?: PlayerControlsRef;
  private _actionSubject = new Subject<VideoPlayerControlAction>();

  public constructor(private overlay: Overlay) {
  }

  public get action$(): Observable<VideoPlayerControlAction> {
    return this._actionSubject.asObservable();
  }

  public set playbackState(value: VideoPlaybackState) {
    if (this._instance) {
      this._instance.playbackState = value;
    }
  }

  public set duration(value: number) {
    if (this._instance) {
      this._instance.duration = value;
    }
  }

  public set timestamp(value: number) {
    if (this._instance) {
      this._instance.timestamp = value;
    }
  }

  public open(focusable: FocusableComponent, state: VideoPlaybackState, timeCode: number, duration: number): void {
    if (this._instance) {
      this.resetTimeout();

      return;
    }

    const overlayRef = this.overlay.create({
      width: '100%',
      height: '120px',
      positionStrategy: this.overlay.position()
        .global()
        .bottom('0')
        .left('0')
        .right('0'),
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      panelClass: 'z-top'
    });

    const portal = new ComponentPortal(PlayerControlsComponent);
    const componentRef = overlayRef.attach(portal);
    const controlsRef = new PlayerControlsRef(componentRef.instance, overlayRef);

    controlsRef.playbackState = state;
    controlsRef.timestamp = timeCode;
    controlsRef.duration = duration;

    controlsRef.action$
      .pipe(
        takeUntil(controlsRef.beforeClose$)
      ).subscribe(action => this._actionSubject.next(action));

    controlsRef.beforeClose$
      .pipe(
        take(1)
      ).subscribe(() => focusable.popFocusableInterceptor());

    controlsRef.afterClosed$
      .pipe(
        take(1)
      ).subscribe(() => this._instance = undefined);

    focusable.pushFocusableInterceptor(controlsRef.instance);

    this._instance = controlsRef;
  }

  public close(): void {
    this._instance?.close();
  }

  public resetTimeout(): void {
    this._instance?.resetTimeout();
  }
}
