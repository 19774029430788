<div class="content flex flex-col gap-1 place-items-center place-content-center">
  <span class="flex-none">ShareView Setup</span>

  <qr-code [darkColor]="'#FFFFFFFF'"
           [lightColor]="'#FF000000'"
           class="flex-none rounded-sm"
           errorCorrectionLevel="L"
           size="180"
           value="a35cfb9b-38fe-4204-be4f-1f43d5e2cede">
  </qr-code>
</div>
