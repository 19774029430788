import { Peer2, ResizeMessage } from './peer2';

export class PeerManager {
  private readonly _peers: Peer2[] = [];
  private _peerId: string | null = null;

  public onIce: (peerId: string, event: RTCIceCandidate) => void = () => console.log('onIce not handled');
  public onDescription: (peerId: string, event: RTCSessionDescriptionInit | null) => void = () => console.error('onDescription not handled.');
  public onEnd: (peerId: string) => void = () => console.error('onEnd not handled.');
  public onResize: (peerId: string, message: ResizeMessage) => void = () => console.error('onResize not handled');
  public getContainerSize: (peerId: string) => ResizeMessage = () => ({ width: 0, height: 0 });

  public set peerId(peerId: string) {
    this._peerId = peerId;
  }

  public get peers(): Peer2[] {
    return this._peers;
  }

  public peerExists(peerId: string): boolean {
    return this.getPeer(peerId) !== null;
  }

  public getPeer(peerId: string): Peer2 | null {
    return this._peers.find(peer => peer.peerId === peerId) ?? null;
  }

  public addPeer(peerId: string): Peer2 {
    if (!this._peerId) {
      console.error('PeerId not set');
      throw new Error('PeerId not set');
    }

    let peer = this.getPeer(peerId);

    if (peer) {
      return peer;
    }

    peer = new Peer2(peerId, this._peerId < peerId);

    peer.onIce = this.onIce;
    peer.onDescription = this.onDescription;
    peer.onEnd = this.onEnd;
    peer.onResize = this.onResize;
    peer.getContainerSize = this.getContainerSize;

    this._peers.push(peer);

    return peer;
  }

  public removePeer(peerId: string, bubble: boolean): void {
    const peer = this.getPeer(peerId);

    if (!peer) {
      console.warn(`Peer not found: ${peerId}`);
      return;
    }

    peer.end(bubble);

    peer.onIce = () => console.error('onIce not handled.');
    peer.onDescription = () => console.error('onDescription not handled.');
    peer.onEnd = () => console.error('onEnd not handled.');
    peer.onResize = () => console.error('onResize not handled');
    peer.getContainerSize = () => ({ width: 0, height: 0 });

    const index = this._peers.indexOf(peer);

    this._peers.splice(index, 1);
  }
}
