import { Selector } from '@ngxs/store';
import { VideoModel } from '@shareview/media-api-client';
import { Category } from '../models';
import { VideoLibraryState } from './video-library.state';
import { VideoLibraryStateModel } from './video-library.state-model';

export class VideoLibrarySelectors {

  @Selector([VideoLibraryState])
  public static loading(state: VideoLibraryStateModel): boolean {
    return state.loading;
  }

  @Selector([VideoLibraryState])
  public static categories(state: VideoLibraryStateModel): Category[] {
    return state.categories;
  }

  @Selector([VideoLibraryState])
  public static categoryId(state: VideoLibraryStateModel): string | null {
    return state.categoryId;
  }

  @Selector([VideoLibraryState])
  public static hasVideos(state: VideoLibraryStateModel): boolean {
    return state.videos.length > 0;
  }

  @Selector([VideoLibraryState])
  public static videos(state: VideoLibraryStateModel): VideoModel[] {
    return state.videos;
  }
}
