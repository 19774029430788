import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DeviceSelectors } from '@shareview/configuration';

@Component({
  selector: 'sv-landing',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturesLandingComponent implements AfterViewInit {
  // TODO Check if app is configured with room/location info.
  // TODO If configured, redirect to library page.
  // TODO If not configured, redirect to onboarding.

  public constructor(private router: Router,
                     private store: Store) {
  }

  public async ngAfterViewInit(): Promise<void> {
    const hasDeviceId$ = this.store.selectOnce(DeviceSelectors.hasDeviceId);
    // TODO Fix this!!!
    const hasDeviceId = true; //await firstValueFrom(hasDeviceId$);
    // const route = hasDeviceId ? '/home' : '/onboarding';
    const route = hasDeviceId ? '/video-library' : '/onboarding';

    await this.router.navigate([route]);
  }
}
