import { WidgetPosition } from '@shareview/sessionmanager-api-client';
import { CastLayout } from '../types';

export interface CastingStateModel {
  sessionId: string | null;
  layout: CastLayout | null;
  widgets: WidgetPosition[];
}

export const DEFAULT_STATE: CastingStateModel = {
  sessionId: null,
  layout: null,
  widgets: []
};
