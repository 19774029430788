import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoggingService } from './logging';
import { TimeService } from './time';

@NgModule({
  imports: [
    CommonModule
  ]
})
export class SharedServicesModule {

  public static forRoot(): ModuleWithProviders<SharedServicesModule> {
    return {
      ngModule: SharedServicesModule,
      providers: [
        LoggingService,
        TimeService
      ]
    };
  }
}
