<div class="toolbar" fxFlex fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">

  <ng-container *ngFor="let item of config?.items ?? []">

    <ng-container [ngSwitch]="item.type">
      <sv-date-time *ngSwitchCase="'date-time'" [value]="dateTimeItem(item)"></sv-date-time>
      <sv-image *ngSwitchCase="'image'" [value]="imageItem(item)"></sv-image>
      <sv-image-text *ngSwitchCase="'image-text'" [value]="imageTextItem(item)"></sv-image-text>
      <sv-text *ngSwitchCase="'text'" [value]="textItem(item)"></sv-text>
    </ng-container>

  </ng-container>

</div>
