import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  ClinicToolbarComponent,
  DateTimeComponent,
  ImageComponent,
  ImageTextComponent,
  TextComponent
} from './components';

@NgModule({
  declarations: [
    ClinicToolbarComponent,
    DateTimeComponent,
    ImageComponent,
    ImageTextComponent,
    TextComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [
    ClinicToolbarComponent
  ]
})
export class ClinicToolbarModule {
}
