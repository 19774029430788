<div #content class="content">
  <ng-container *ngFor="let category of categories; index as index; trackBy: trackByFn">
    <div>
      <sv-category-button #button
                          [active]="isFocused"
                          [count]="category.videoCount"
                          [id]="category.id"
                          [name]="category.name"
                          [selected]="category.id === selectedCategoryId"
                          (click)="categoryButtonClicked(button)">
      </sv-category-button>
    </div>
  </ng-container>
</div>
