import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { Store } from '@ngxs/store';
import { EnvironmentSelectors } from '@shareview/configuration';
import { WidgetPosition } from '@shareview/sessionmanager-api-client';
import { Observable, Subject } from 'rxjs';
import { CastingSelectors } from '../../state';

export interface BeginSessionMessage {
  widgets: WidgetPosition[];
}

export interface CreateSessionMessage {
  sessionId: string;
  layout: string;
  widgets: WidgetPosition[];
}

export interface ChangeLayoutMessage {
  layout: string;
  widgets: WidgetPosition[];
}

export interface UpdateWidgetContentMessage {
  widgetId: number;
}

@Injectable()
export class CastingHubService {
  private readonly _connection: HubConnection;
  private readonly _sessionCreatedSubject = new Subject<CreateSessionMessage>();
  private readonly _beginSessionSubject = new Subject<BeginSessionMessage>();
  private readonly _endSessionSubject = new Subject<void>();
  private readonly _changeLayoutSubject = new Subject<ChangeLayoutMessage>();
  private readonly _updateWidgetContentSubject = new Subject<UpdateWidgetContentMessage>();
  private readonly _resetSubject = new Subject<void>();

  public constructor(private store: Store) {
    const baseUrlSelector = EnvironmentSelectors.serviceUrl('session-manager');
    // TODO Move this to a separate impl so this can be a provider and hotswap
    // TODO Or dynamic via factory injector?
    const baseUrl = this.store.selectSnapshot(baseUrlSelector);
    const hubUrl = `${baseUrl}/hubs/casting`;

    // TODO Add authentication, retry, etc.
    this._connection = new HubConnectionBuilder()
      .withUrl(hubUrl)
      .build();

    this._connection.on('OnSessionCreated', (message: CreateSessionMessage) => this._sessionCreatedSubject.next(message));
    this._connection.on('BeginSession', (message: BeginSessionMessage) => this._beginSessionSubject.next(message));
    this._connection.on('EndSession', () => this._endSessionSubject.next());
    this._connection.on('ChangeLayout', (message: ChangeLayoutMessage) => this._changeLayoutSubject.next(message));
    this._connection.on('UpdateWidgetContent', (message: UpdateWidgetContentMessage) => this._updateWidgetContentSubject.next(message));
    this._connection.on('ResetSession', () => this._resetSubject.next());
  }

  public get sessionCreated$(): Observable<CreateSessionMessage> {
    return this._sessionCreatedSubject.asObservable();
  }

  public get beginSession$(): Observable<BeginSessionMessage> {
    return this._beginSessionSubject.asObservable();
  }

  public get endSession$(): Observable<void> {
    return this._endSessionSubject.asObservable();
  }

  public get changeLayout$(): Observable<ChangeLayoutMessage> {
    return this._changeLayoutSubject.asObservable();
  }

  public get updateWidgetContent$(): Observable<UpdateWidgetContentMessage> {
    return this._updateWidgetContentSubject.asObservable();
  }

  public get reset$(): Observable<void> {
    return this._resetSubject.asObservable();
  }

  public async connect(): Promise<boolean> {
    if (this._connection.state === HubConnectionState.Disconnected) {
      await this._connection.start();

      return true;
    }

    return false;
  }

  public async disconnect(): Promise<void> {
    await this._connection.stop();
  }

  public async createSession(clinicId: string, deviceId: string): Promise<void> {
    await this._connection.send('CreateSession', { clinicId, deviceId });
  }

  public async castingStarted(sessionId: string): Promise<void> {
    await this._connection.send('CastingStarted', { sessionId });
  }

  public async join(): Promise<void> {
    const sessionId = this.store.selectSnapshot(CastingSelectors.sessionId);
    const deviceId = '00000000-0000-0000-0000-000000000000';

    await this._connection.send('Join', { sessionId, deviceId });
  }

  public async leave(): Promise<void> {
    const sessionId = this.store.selectSnapshot(CastingSelectors.sessionId);

    if (sessionId) {
      await this._connection.send('Leave', { sessionId });
    }
  }
}
