import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FocusableComponent } from '@shareview/shared/focusable';

@Component({
  selector: 'sv-control-button',
  templateUrl: './control-button.component.html',
  styleUrls: ['./control-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => ControlButtonComponent) }]
})
export class ControlButtonComponent extends FocusableComponent {
}
