import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { VideoModel } from '@shareview/media-api-client';
import { SharedComponentsModule } from '@shareview/shared/components';
import { FocusableComponent } from '@shareview/shared/focusable';

@Component({
  selector: 'sv-video-card',
  standalone: true,
  imports: [CommonModule, SharedComponentsModule, NgOptimizedImage],
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => VideoCardComponent) }]
})
export class VideoCardComponent extends FocusableComponent {
  @Input()
  public video!: VideoModel;

  @Input()
  public active = false;

  @Output()
  public videoSelect = new EventEmitter<VideoModel>();

  public override handleKeyPress(event: KeyboardEvent): boolean {
    switch (event.key) {
      case 'Enter':
        this.videoSelect.emit(this.video);
        return true;

      default:
        return false;
    }
  }
}
