<div (@slide.done)="onAnimationEnd($event)"
     (@slide.start)="onAnimationStart($event)"
     [@slide]="animationState"
     class="content"
     fxLayout="row"
     fxLayoutAlign="start center"
     fxLayoutGap="16px">

  <sv-control-button (click)="playClicked()">
    <ng-container [ngSwitch]="playbackState">
      <ng-container *ngSwitchCase="'playing'">pause</ng-container>
      <ng-container *ngSwitchCase="'stopped'">play_arrow</ng-container>
      <ng-container *ngSwitchCase="'paused'">play_arrow</ng-container>
    </ng-container>
  </sv-control-button>

  <span></span>

  <sv-control-button (click)="rewindClicked()">
    fast_rewind
  </sv-control-button>

  <sv-control-button (click)="fastForwardClicked()">
    fast_forward
  </sv-control-button>

  <span></span>

  <mat-progress-bar [value]="progress" color="accent" fxFlex mode="determinate"></mat-progress-bar>
  <span>{{ timestamp | progressTime:duration }}</span>
</div>
