import { Directive, Input } from '@angular/core';
import { ClinicToolbarImageItem } from '../../interfaces';

@Directive()
export class ImageComponentStyle {
  private _value?: ClinicToolbarImageItem;

  public get value(): ClinicToolbarImageItem | undefined {
    return this._value;
  }

  @Input()
  public set value(value: ClinicToolbarImageItem | undefined) {
    this._value = value;
  }
}
