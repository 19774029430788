import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollableFocusableComponent } from './components/scrollable-focusable/scrollable-focusable.component';
import { ScrollableViewportDirective } from './directives/scrollable-viewport.directive';

@NgModule({
  declarations: [
    ScrollableFocusableComponent,
    ScrollableViewportDirective
  ],
  imports: [CommonModule],
  exports: [
    ScrollableFocusableComponent,
    ScrollableViewportDirective
  ]
})
export class SharedFocusableModule {
}
