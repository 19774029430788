import { Route } from '@angular/router';
import { videoLibraryRoutes } from '@shareview/features/video-library';
import { HomePageComponent } from './pages';
import { PersonalizationPageComponent } from './pages/personalization-page/personalization-page.component';

export const homeRoutes: Route[] = [
  {
    path: '',
    component: HomePageComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'video-library' },
      { path: 'video-library', loadChildren: () => videoLibraryRoutes },

      { path: '', pathMatch: 'full', redirectTo: 'personalization', outlet: 'sidebar' },
      { path: 'personalization', component: PersonalizationPageComponent, outlet: 'sidebar' },
    ]
  },
];
