import { Injectable } from '@angular/core';
import {
  ClinicConfig,
  ClinicConfigThemeService,
  ClinicConfigThemeTransforms,
  StyleVars
} from '@shareview/clinic/config';

@Injectable()
export class VideoLibraryCategoryConfigThemeService extends ClinicConfigThemeService {

  public applyThemeConfig(config: ClinicConfig | null): StyleVars {
    const categoryConfig = config?.home?.videoLibrary?.categories;

    return [
      this.style('--clinic-video-library-category-background-color', categoryConfig?.backgroundColor),
      this.style('--clinic-video-library-category-text-color', categoryConfig?.textColor),
      this.style('--clinic-video-library-category-font-size', categoryConfig?.fontSize, ClinicConfigThemeTransforms.toPx),
      this.style('--clinic-video-library-category-font-weight', categoryConfig?.fontWeight),

      this.style('--clinic-video-library-category-selected-background-color', categoryConfig?.selectBackgroundColor),
      this.style('--clinic-video-library-category-selected-text-color', categoryConfig?.selectTextColor),
      this.style('--clinic-video-library-category-selected-font-size', categoryConfig?.selectFontSize, ClinicConfigThemeTransforms.toPx),

      this.style('--clinic-video-library-category-active-background-color', categoryConfig?.activeBackgroundColor),
      this.style('--clinic-video-library-category-active-text-color', categoryConfig?.activeTextColor),
      this.style('--clinic-video-library-category-active-font-size', categoryConfig?.activeFontSize, ClinicConfigThemeTransforms.toPx)
    ];
  }
}
