<ng-container *ngFor="let button of buttons">
  <div class="button">
   <div class="wrapper">
<!--     <mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon>-->
     <fa-icon *ngIf="button.def" [icon]="button.def" size="2x" />
<!--     <img class="icon" [src]="'/assets/' + button.icon + '.png'" width="32" height="32" />-->
     <span>{{ button.label }}</span>
   </div>
  </div>
</ng-container>

<!--<div class="flex-1"></div>-->

<!--<div class="remote w-[60px] mt-[210px]">-->
<!--  <img class="mx-[12px]" width="60" height="273" ngSrc="/assets/Remote.png">-->
<!--</div>-->

<!--<div class="flex-1"></div>-->
