import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Output, TrackByFunction } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ClinicComponentsModule } from '@shareview/clinic/components';
import { VideoModel } from '@shareview/media-api-client';
import { FocusableComponent, FocusableViewChildrenComponent, SharedFocusableModule } from '@shareview/shared/focusable';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { VideoCardComponent } from '../video-card';

@Component({
  selector: 'sv-video-card-row',
  standalone: true,
  imports: [CommonModule, VideoCardComponent, FlexModule, ClinicComponentsModule, InlineSVGModule, SharedFocusableModule],
  templateUrl: './video-card-row.component.html',
  styleUrls: ['./video-card-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('indicator', [
      transition('* => true', [
        style({ opacity: 0 }),
        animate('250ms 500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('spinner', [
      transition('* => true', [
        style({ opacity: 0 }),
        animate('250ms 250ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('card', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms 250ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ],
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => VideoCardRowComponent) }]
})
export class VideoCardRowComponent extends FocusableViewChildrenComponent<VideoCardComponent, VideoModel> {
  @Output()
  public videoSelect = new EventEmitter<VideoModel>();

  public constructor() {
    super();

    this.focusScrollOrientation = 'horizontal';
  }

  public override get canFocus(): boolean {
    return this.items.length > 0;
  }

  protected get trackByVideoFn(): TrackByFunction<VideoModel> {
    return (index, item) => item.id ?? index;
  }

  public override handleKeyPress(event: KeyboardEvent): boolean {
    return this.handleChildKeyPress(event);
  }

  protected onVideoSelected(video: VideoModel): void {
    this.videoSelect.emit(video);
  }
}
