import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { OnboardingActions } from './onboarding.actions';
import { DEFAULT_STATE, OnboardingStateModel } from './onboarding.state-model';
import { STATE_TOKEN } from './onboarding.state-token';

type Context = StateContext<OnboardingStateModel>;

@State<OnboardingStateModel>({
  name: STATE_TOKEN,
  defaults: DEFAULT_STATE
})
@Injectable()
export class OnboardingState {

  @Action(OnboardingActions.SetRegistrationCode)
  public setRegistrationCode(context: Context, { code }: OnboardingActions.SetRegistrationCode) {
    context.patchState({ code });
  }

  @Action(OnboardingActions.Reset)
  public reset(context: Context) {
    context.patchState(DEFAULT_STATE);
  }
}
