import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SERVICE_MEDIA_API_BASE_URL } from '@shareview/media-api-client';
import { ShareViewApiConfig } from './models/shareview-api-config';
import { CLINIC_ID } from './tokens/clinic.tokens';
import { API_REFRESH_INTERVAL } from './tokens/shareview-api.tokens';

@NgModule({
  imports: [CommonModule]
})
export class ClinicApiModule {

  public static forRoot(config: ShareViewApiConfig): ModuleWithProviders<ClinicApiModule> {
    return {
      ngModule: ClinicApiModule,
      providers: [
        { provide: CLINIC_ID, useValue: config.clinicId },
        { provide: API_REFRESH_INTERVAL, useValue: config.refreshInterval },
        { provide: SERVICE_MEDIA_API_BASE_URL, useValue: config.mediaApiUrl }
      ]
    };
  }
}
