import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TimeService } from '@shareview/shared/services';
import { NgLetModule } from 'ng-let';
import { Observable } from 'rxjs';
import { DateTimeFormatPipe } from '../../pipes';

@Component({
  selector: 'sv-toolbar-time',
  standalone: true,
  imports: [
    CommonModule,
    NgLetModule,
    DateTimeFormatPipe
  ],
  templateUrl: './toolbar-time.component.html',
  styleUrls: ['./toolbar-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarTimeComponent {

  public constructor(private timeService: TimeService) {
  }

  protected get time$(): Observable<Date> {
    return this.timeService.timestamp$;
  }
}
