import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ClinicToolbarDateTimeItem,
  ClinicToolbarImageItem,
  ClinicToolbarImageTextItem,
  ClinicToolbarTextItem
} from '../../interfaces';
import { ClinicToolbarItem } from '../../interfaces/clinic-toolbar-item';
import { ClinicToolbarComponentStyle } from './clinic-toolbar.component.style';

@Component({
  selector: 'sv-clinic-toolbar',
  templateUrl: './clinic-toolbar.component.html',
  styleUrls: ['./clinic-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClinicToolbarComponent extends ClinicToolbarComponentStyle {

  public constructor() {
    super();

    this.config = { items: [] };
  }

  public dateTimeItem(item: ClinicToolbarItem): ClinicToolbarDateTimeItem {
    return item as ClinicToolbarDateTimeItem;
  }

  public imageItem(item: ClinicToolbarItem): ClinicToolbarImageItem {
    return item as ClinicToolbarImageItem;
  }

  public imageTextItem(item: ClinicToolbarItem): ClinicToolbarImageTextItem {
    return item as ClinicToolbarImageTextItem;
  }

  public textItem(item: ClinicToolbarItem): ClinicToolbarTextItem {
    return item as ClinicToolbarTextItem;
  }
}
