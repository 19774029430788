import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { AfterViewInit, APP_INITIALIZER, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ClinicComponentsModule } from '@shareview/clinic/components';
import { ClinicConfigModule, ClinicConfigService } from '@shareview/clinic/config';
import { ClinicToolbarModule } from '@shareview/clinic/toolbar';
import { SharedComponentsModule } from '@shareview/shared/components';
import { FocusRootComponent } from '@shareview/shared/focusable';
import { NgLetModule } from 'ng-let';
import { environment } from '../../../../../../../apps/clinic-display/src/environments/environment';
import { PatientCardComponent, QrCodeCardComponent, ToolbarComponent } from '../../components';


@Component({
  selector: 'sv-video-library-page',
  standalone: true,
  imports: [
    CommonModule,
    ToolbarComponent,
    PatientCardComponent,
    QrCodeCardComponent,
    ClinicComponentsModule,
    ClinicConfigModule,
    ClinicToolbarModule,
    FlexModule,
    NgLetModule,
    SharedComponentsModule,
  ],
  templateUrl: './video-library-page.component.html',
  styleUrls: ['./video-library-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
  animations: [
    trigger('loading', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class VideoLibraryPageComponent extends FocusRootComponent implements OnInit, AfterViewInit, OnDestroy {
  public constructor(public configService: ClinicConfigService) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.configService.clinicConfig = environment.clinicConfig;
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public override handleKeyPress(event: KeyboardEvent): boolean {
    return super.handleKeyPress(event);
  }
}
