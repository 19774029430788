export class ClinicConfigThemeTransforms {

  public static toDeg(value: number): string {
    return `${value}deg`;
  }

  public static toPercent(value: number): string {
    return `${value}%`;
  }

  public static toPx(value: number): string {
    return `${value}px`;
  }
}
