import { OverlayRef } from '@angular/cdk/overlay';
import { filter, Observable, Subject, take } from 'rxjs';
import { ControlIndicatorComponent } from '../components/control-indicator/control-indicator.component';
import { ControlIndicator } from '../types/control-indicator.types';

export class ControlIndicatorRef {
  private _instance?: ControlIndicatorComponent;
  private _overlayRef?: OverlayRef;

  private readonly _afterOpened = new Subject<void>();
  private readonly _beforeClose = new Subject<void>();
  private readonly _afterClosed = new Subject<void>();

  public constructor(instance: ControlIndicatorComponent, overlayRef: OverlayRef) {
    this._instance = instance;
    this._overlayRef = overlayRef;

    this._instance.animationStateChanged.pipe(
      filter(event => event.phaseName === 'done'),
      filter(event => event.toState === 'enter'),
      take(1)
    ).subscribe(() => {
      this._afterOpened.next();
      this._afterOpened.complete();
    });
  }

  public get afterOpened$(): Observable<void> {
    return this._afterOpened.asObservable();
  }

  public get beforeClose$(): Observable<void> {
    return this._beforeClose.asObservable();
  }

  public get afterClosed$(): Observable<void> {
    return this._afterClosed.asObservable();
  }

  public get indicator(): ControlIndicator | undefined {
    return this._instance?.indicator;
  }

  public set indicator(value: ControlIndicator | undefined) {
    if (this._instance) {
      this._instance.indicator = value;
    }
  }

  public open(): void {
    this._instance?.startEnterAnimation();
  }

  public close(): void {
    this._instance?.animationStateChanged.pipe(
      filter(event => event.phaseName === 'done'),
      filter(event => event.toState === 'leave'),
      take(1)
    ).subscribe(() => {
      this._overlayRef?.detach();
      this._overlayRef?.detachBackdrop();
      this._overlayRef?.dispose();

      this._afterClosed.next();
      this._afterClosed.complete();

      this._instance = undefined;
      this._overlayRef = undefined;
    });

    this._instance?.startExitAnimation();
  }
}
