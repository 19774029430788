import { Injectable } from '@angular/core';
import { ClinicConfigService } from '@shareview/clinic/config';
import { VideoLibraryModel, VideoModel } from '@shareview/media-api-client';
import { FocusableComponent } from '@shareview/shared/focusable';
import { VideoMetadata, VideoPlayerService } from '@shareview/video/player';
import { take } from 'rxjs';

@Injectable()
export class ClinicVideoPlayerOverlayService {

  public constructor(private configService: ClinicConfigService,
                     private videoPlayerService: VideoPlayerService) {
  }

  private get videoPosterUrl(): string | undefined {
    return this.configService.clinicConfig?.videos?.posterImageUrl;
  }

  public openVideo(parent: FocusableComponent, library: VideoLibraryModel, video?: VideoModel): void {
    if (!video) {
      return;
    }

    const metadata: VideoMetadata = {
      // posterUrl: library.posterUrl ?? this.videoPosterUrl,
      posterUrl: this.videoPosterUrl,
      videoUrl: video.videoUrl ?? '',
      mimeType: video.videoMimeType ?? ''
    };

    const playerRef = this.videoPlayerService.open(metadata);

    if (playerRef.instance) {
      parent.pushFocusableInterceptor(playerRef.instance);
    }

    playerRef.afterClosed$.pipe(
      take(1)
    ).subscribe(() => {
      parent.popFocusableInterceptor();
    });
  }
}
