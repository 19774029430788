import { Injectable } from '@angular/core';
import { ClinicConfigService } from '@shareview/clinic/config';
import { FocusableComponent } from '@shareview/shared/focusable';
import { VideoMetadata, VideoPlayerRef, VideoPlayerService } from '@shareview/video/player';
import { take } from 'rxjs';

type Timeout = ReturnType<typeof setTimeout>;

@Injectable({
  providedIn: 'root'
})
export class ScreensaverService {
  private _timer?: Timeout;
  private _videoPlayerRef?: VideoPlayerRef;

  public constructor(private configService: ClinicConfigService,
                     private videoPlayerService: VideoPlayerService) {

    this.videoPlayerService
      .onOpen$
      .subscribe(() => this.stopScreensaverTimer());

    this.videoPlayerService
      .onClose$
      .subscribe(() => this.startScreensaverTimer());
  }

  private _focusable?: FocusableComponent;

  public set focusable(value: FocusableComponent | undefined) {
    if (this._videoPlayerRef) {
      this.videoPlayerService.close();
    }

    this._focusable = value;
  }

  private get videoMetadata(): VideoMetadata | undefined {
    if (!this.configService.clinicConfig?.screensaver) {
      return undefined;
    }

    return {
      videoUrl: this.configService.clinicConfig.screensaver.videoUrl,
      mimeType: this.configService.clinicConfig.screensaver.mimeType
    };
  }

  private get videoTimeout(): number | undefined {
    if (!this.configService.clinicConfig?.screensaver) {
      return undefined;
    }

    return this.configService.clinicConfig.screensaver.timeout;
  }

  public showScreensaver(): void {
    if (this.videoPlayerService.isOpened || this._videoPlayerRef || !this.videoMetadata) {
      return;
    }

    this._videoPlayerRef = this.videoPlayerService.open(this.videoMetadata);

    this._videoPlayerRef.afterClosed$
      .pipe(
        take(1)
      ).subscribe(() => {
      this._focusable?.popFocusableInterceptor();
      this._videoPlayerRef = undefined;
    });

    if (this._videoPlayerRef.instance) {
      this._focusable?.pushFocusableInterceptor(this._videoPlayerRef.instance);
    }
  }

  public resetTimer(): void {
    this.stopScreensaverTimer();
    this.startScreensaverTimer();
  }

  private startScreensaverTimer(): void {
    if (this._timer || !this.videoTimeout) {
      return;
    }

    this._timer = setTimeout(() => this.showScreensaver(), this.videoTimeout * 60 * 1000);
  }

  private stopScreensaverTimer(): void {
    if (this._timer) {
      clearTimeout(this._timer);

      this._timer = undefined;
    }
  }
}
