import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, forwardRef, Inject, OnInit } from '@angular/core';
import { API_REFRESH_INTERVAL, CLINIC_ID } from '@shareview/clinic/api';
import { VideoCategoryModel, VideoLibraryModel, VideoModel } from '@shareview/media-api-client';
import { FocusableComponent } from '@shareview/shared/focusable';
import { distinctUntilChanged, interval, Observable, startWith, switchMap, takeUntil } from 'rxjs';
import { VideoCategoryModelOld } from '../../types/video-category-model-old';
import { VideoLibraryModelOld } from '../../types/video-library-model-old';

@Component({
  selector: 'sv-video-library',
  templateUrl: './video-library.component.html',
  styleUrls: ['./video-library.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => VideoLibraryComponent) }]
})
export class VideoLibraryComponent extends FocusableComponent implements OnInit {
  private _labels?: VideoCategoryModel;

  public constructor(@Inject(API_REFRESH_INTERVAL)
                     private refreshInterval: number,
                     @Inject(CLINIC_ID)
                     private clinicId: string,
                     private httpClient: HttpClient) {
    // private videoLibraryService: ClientV1) {
    super();
  }

  private _library?: VideoLibraryModelOld;

  public get library(): VideoLibraryModelOld {
    return this._library ?? {};
  }

  public get categories(): VideoCategoryModelOld[] {
    return this._library?.categories ?? [];
  }

  public get videos(): VideoModel[] {
    return this._labels?.videos ?? [];
  }

  public ngOnInit(): void {
      interval(this.refreshInterval).pipe(
        startWith(0),
        takeUntil(this.onDestroy$),
        // switchMap(() => this.videoLibraryService.videoLibrary_GetForClinic(this.clinicId)),
        switchMap(() => this.getVideoLibrary(this.clinicId)),
        distinctUntilChanged()
      ).subscribe(library => {
        this._library = library;

        this.changeDetectorRef.markForCheck();
      });
  }

  private getVideoLibrary(clinicId: string): Observable<VideoLibraryModel> {
    return this.httpClient.get<VideoLibraryModel>(`https://media.services.myshareview.com/v1/clinics/${clinicId}/media/library`);
  }

  public override handleKeyPress(event: KeyboardEvent): boolean {
    if (this.handleChildKeyPress(event)) {
      return true;
    }

    switch (event.key) {
      case 'ArrowLeft':
        this.decrementChildFocus();
        break;

      case 'ArrowRight':
        this.incrementChildFocus();
        break;

      case 'Escape':
      case 'XF86Back':
        this.currentFocusChildIndex = 0;

        return true;

      default:
        return false;
    }

    return true;
  }

  public onCategoryChanged(category?: VideoCategoryModel): void {
    this._labels = undefined;

    setTimeout(() => {
      this._labels = category;
      this.changeDetectorRef.markForCheck();
    });
  }
}
