import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import { ClinicConfigService } from '@shareview/clinic/config';
import HLS from 'hls.js';
import { BehaviorSubject, Observable } from 'rxjs';
import { VideoPlaybackState } from '../../types/video.types';

const LOG_TAG = 'HLS Player';

@Component({
  selector: 'sv-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('loading', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms 500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class VideoComponent implements OnInit, OnDestroy {
  @Input()
  public posterUrl?: string;

  @Input()
  public videoUrl?: string;

  @ViewChild('video', { static: true })
  private videoElement!: ElementRef<HTMLVideoElement>;
  private readonly _currentTimeSubject = new BehaviorSubject<number>(0);
  private readonly _durationSubject = new BehaviorSubject<number>(0);
  private readonly _endedSubject = new BehaviorSubject<boolean>(false);
  private readonly _playbackStateSubject = new BehaviorSubject<VideoPlaybackState>('stopped');
  private readonly _hls = new HLS({
    maxBufferHole: 2.5,
    maxFragLookUpTolerance: 1
  });

  public constructor(private changeDetectorRef: ChangeDetectorRef,
                     private clinicConfigService: ClinicConfigService) {
    // private loggingService: LoggingService) {
  }

  private _loading = true;

  public get loading(): boolean {
    return this._loading;
  }

  private set loading(value: boolean) {
    this._loading = value;

    this.changeDetectorRef.markForCheck();
  }

  public get sources(): string[] {
    if (this.videoUrl) {
      return [this.videoUrl];
    }

    return [];
  }

  public get trackBySource(): TrackByFunction<string> {
    return (index, item) => item;
  }

  public get playbackState$(): Observable<VideoPlaybackState> {
    return this._playbackStateSubject.asObservable();
  }

  public get playbackState(): VideoPlaybackState {
    if (this.videoElement.nativeElement.ended) {
      return 'stopped';
    } else if (this.videoElement.nativeElement.paused) {
      return 'paused';
    } else {
      return 'playing';
    }
  }

  public get currentTime$(): Observable<number> {
    return this._currentTimeSubject.asObservable();
  }

  public get currentTime(): number {
    return this.videoElement.nativeElement.currentTime;
  }

  public get duration$(): Observable<number> {
    return this._durationSubject.asObservable();
  }

  public get duration(): number {
    return this.videoElement.nativeElement.duration;
  }

  public get ended$(): Observable<boolean> {
    return this._endedSubject.asObservable();
  }

  public get ended(): boolean {
    return this.videoElement.nativeElement.ended;
  }

  private get clinicId(): string {
    return this.clinicConfigService.clinicConfig?.clinicId ?? 'unknown';
  }

  public ngOnInit(): void {
    this.videoElement.nativeElement.onplay = () => {
      console.log('onplay');
      this._endedSubject.next(false);
      this._playbackStateSubject.next(this.playbackState);

      this.loading = false;
    };

    this.videoElement.nativeElement.onpause = () => {
      console.log('onpause');
      this._playbackStateSubject.next(this.playbackState);

      this.loading = false;
    };

    this.videoElement.nativeElement.onended = () => {
      this._endedSubject.next(true);
      this._playbackStateSubject.next(this.playbackState);

      this.loading = false;
    };

    this.videoElement.nativeElement.ondurationchange = () => this._durationSubject.next(this.duration);
    this.videoElement.nativeElement.ontimeupdate = () => this._currentTimeSubject.next(this.currentTime);
    this.videoElement.nativeElement.onplaying = () => {
      console.log('playing');
      this.loading = false;
    };
    this.videoElement.nativeElement.onerror = (event) => {
      // this.videoElement.nativeElement.load();
      console.error(event);

      // TODO Fix this!
      // this.loggingService.logError(this.clinicId, LOG_TAG, { type: 'Video element error', event: event });

      throw event;
    };

    this._hls.on(HLS.Events.ERROR, (event, data) => {
      console.error(event, data);

      // TODO Fix this!
      // this.loggingService.logError(this.clinicId, LOG_TAG, { type: 'HLS player error', event: event, data: data });

      if (!data.fatal) {
        return;
      }

      switch (data.type) {
        case HLS.ErrorTypes.NETWORK_ERROR:
          this._hls.startLoad();
          break;

        case HLS.ErrorTypes.MEDIA_ERROR:
          this._hls.recoverMediaError();
          break;

        default:
          this.stop();
          break;
      }
    });
  }

  public ngOnDestroy(): void {
    this._hls.detachMedia();
    this._hls.destroy();

    this._currentTimeSubject.complete();
    this._durationSubject.complete();
    this._playbackStateSubject.complete();
  }

  public loadVideo(url: string, mimeType: string): void {
    if (HLS.isSupported() && mimeType.toLowerCase() === 'application/vnd.apple.mpegurl') {
      this._hls.loadSource(url);
      this._hls.attachMedia(this.videoElement.nativeElement);
    } else {
      this.videoElement.nativeElement.src = url;
    }
  }

  public play(): void {
    this.videoElement.nativeElement.play().then();
  }

  public pause(): void {
    this.videoElement.nativeElement.pause();
  }

  public playPause(): void {
    switch (this.playbackState) {
      case 'playing':
        this.pause();
        break;

      case 'paused':
      case 'stopped':
        this.play();
        break;
    }
  }

  public stop(): void {
    this.pause();

    this.videoElement.nativeElement.currentTime = 0;
  }

  public skipForward(amount = 3): void {
    this.videoElement.nativeElement.currentTime += amount;
  }

  public skipBack(amount = 3): void {
    this.videoElement.nativeElement.currentTime -= amount;
  }
}
