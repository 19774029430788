import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[svBackgroundImage]'
})
export class BackgroundImageDirective {
  @HostBinding('style.background-image')
  private _backgroundImage?: string;

  @HostBinding('style.background-position')
  private _backgroundPosition?: string;

  @HostBinding('style.background-repeat')
  private _backgroundRepeat?: string;

  @HostBinding('style.background-size')
  private _backgroundSize?: string;

  @Input()
  public set url(value: string | undefined) {
    this._backgroundImage = value ? `url('${value}')` : undefined;
    this._backgroundPosition = value ? 'center center' : undefined;
    this._backgroundRepeat = value ? 'no-repeat' : undefined;
    this._backgroundSize = value ? 'cover' : undefined;
  }
}
