import { Directive, HostBinding, Input } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { ImagePlacement } from '../../enums';
import { ClinicToolbarImageTextItem } from '../../interfaces';

const FLEX_ROW_LTR = 'row';
const FLEX_ROW_RLT = 'row-reverse';

@Directive()
export class ImageTextComponentStyle {
  @HostBinding('style.flex-direction')
  private flexDirection?: SafeStyle;

  @HostBinding('style.color')
  private textColor?: SafeStyle;

  @HostBinding('style.font-size.px')
  private fontSize?: number;

  @HostBinding('style.font-weight')
  private fontWeight?: number;

  @HostBinding('style.gap.px')
  private gap?: number;

  private _value?: ClinicToolbarImageTextItem;

  public get value(): ClinicToolbarImageTextItem | undefined {
    return this._value;
  }

  @Input()
  public set value(value: ClinicToolbarImageTextItem | undefined) {
    this._value = value;

    this.flexDirection = value?.imagePlacement === ImagePlacement.right ? FLEX_ROW_RLT : FLEX_ROW_LTR;
    this.textColor = value?.textColor;
    this.fontSize = value?.fontSize;
    this.fontWeight = value?.fontWeight;
    this.gap = value?.gap;
  }
}
