import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { DeviceActions } from './device.actions';
import { DEFAULT_STATE, DeviceStateModel } from './device.state-model';
import { STATE_TOKEN } from './device.state-token';

type Context = StateContext<DeviceStateModel>;

@State<DeviceStateModel>({
  name: STATE_TOKEN,
  defaults: DEFAULT_STATE
})
@Injectable()
export class DeviceState {

  @Action(DeviceActions.SetClinicId)
  public setClinicId(context: Context, { clinicId }: DeviceActions.SetClinicId) {
    context.patchState({ clinicId });
  }

  @Action(DeviceActions.SetDeviceId)
  public setDeviceId(context: Context, { deviceId }: DeviceActions.SetDeviceId) {
    context.patchState({ deviceId });
  }

  @Action(DeviceActions.Reset)
  public reset(context: Context) {
    context.patchState(DEFAULT_STATE);
  }
}
