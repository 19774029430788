import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';

export interface UserJoinMessage {
  channel: string;
  userId: string;
}

export interface UserLeaveMessage {
  channel: string;
  userId: string;
}

export interface UserHelloMessage {
  fromUserId: string;
  toUserId: string;
  channel: string;
}

export interface RtcMessage {
  fromUserId: string;
  toUserId: string;
  candidate?: string;
  description?: string;
}

export class SignalingService {
  private readonly _hub: HubConnection;

  public onJoin: (message: UserJoinMessage) => void = () => console.error('onJoin not handled');
  public onLeave: (message: UserLeaveMessage) => void = () => console.error('onLeave not handled');
  public onHello: (message: UserHelloMessage) => void = () => console.error('onHello not handled');
  public onRtc: (message: RtcMessage) => void = () => console.error('onRtc not handled');

  public constructor() {
    this._hub = new HubConnectionBuilder()
      .withUrl('https://videocall.services.shareview.dev/hubs/webrtc')
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this._hub.on('Join', (channel: string, userId: string) => {
      this.onJoin({ channel, userId });
    });

    this._hub.on('Leave', (channel: string, userId: string) => {
      this.onLeave({ channel, userId });
    });

    this._hub.on('Hello', (fromUserId: string, toUserId: string, channel: string) => {
      this.onHello({ channel, fromUserId, toUserId });
    });

    this._hub.on('Rtc', (fromUserId: string, toUserId: string, candidate?: string, description?: string) => {
      this.onRtc({ fromUserId, toUserId, candidate, description });
    });
  }

  public async join(channel: string, userId: string): Promise<void> {
    await this._hub.invoke('Join', channel, userId);
  }

  public async hello(channel: string, fromUserId: string, toUserId: string): Promise<void> {
    await this._hub.invoke('Hello', fromUserId, toUserId, channel);
  }

  public async leave(channel: string, userId: string): Promise<void> {
    await this._hub.invoke('Leave', channel, userId);
  }

  public async rtc(channel: string, fromUserId: string, toUserId: string, candidate?: unknown, description?: unknown): Promise<void> {
    await this._hub.invoke('Rtc', fromUserId, toUserId, channel, candidate ? JSON.stringify(candidate) : null, description ? JSON.stringify(description) : null);
  }

  public async connect(): Promise<void> {
    if (this._hub.state === HubConnectionState.Disconnected) {
      await this._hub.start();
    }
  }

  public async disconnect(): Promise<void> {
    if (this._hub.state !== HubConnectionState.Disconnected) {
      await this._hub.stop();
    }
  }
}
