// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace VideoLibraryActions {
  const NAMESPACE = '[VideoLibrary]';

  export class GetCategories {
    public static readonly type = `${NAMESPACE} Get Categories`;

    public constructor(public libraryId: string) {
    }
  }

  export class GetVideosForCategory {
    public static readonly type = `${NAMESPACE} Get Videos For Category`;

    public constructor(public categoryId: string) {
    }
  }

  export class Reset {
    public static readonly type = `${NAMESPACE} Reset`;
  }
}
