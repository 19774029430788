import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import {
  ClinicConfigThemeTransforms,
  ClinicHomeQrCodeConfig,
  CssStyleTransform,
  CssStyleVar
} from '@shareview/clinic/config';
import { QrCodeErrorCorrectionLevel, RGBAColor } from 'ng-qrcode/lib/types';

@Component({
  selector: 'sv-qr-code-card',
  templateUrl: './qr-code-card.component.html',
  styleUrls: ['./qr-code-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QrCodeCardComponent implements AfterViewInit {
  @Input()
  public config?: ClinicHomeQrCodeConfig | undefined;

  public constructor(private elementRef: ElementRef) {
  }

  private _url?: string;

  @Input()
  public get url(): string | undefined {
    return this._url ?? this.config?.url;
  }

  public set url(value: string | undefined) {
    this._url = value;
  }

  public get title(): string | undefined {
    return this.config?.title;
  }

  public get caption(): string | undefined {
    return this.config?.caption;
  }

  public get size(): number {
    return this.config?.qrCodeSize ?? 255;
  }

  public get errorCorrectionLevel(): QrCodeErrorCorrectionLevel {
    return this.config?.errorCorrectionLevel ?? 'H';
  }

  public get centerImageUrl(): string | undefined {
    return this.config?.centerImageUrl;
  }

  public get centerImageSize(): number | undefined {
    return this.config?.centerImageSize;
  }

  public get backgroundColor(): RGBAColor | undefined {
    return this.config?.backgroundColor as RGBAColor;
  }

  public get foregroundColor(): RGBAColor | undefined {
    return this.config?.foregroundColor as RGBAColor;
  }

  public ngAfterViewInit(): void {
    this.style('--clinic-qrcode-background-color', this.config?.backgroundColor);

    this.style('--clinic-qrcode-title-text-color', this.config?.titleTextColor);
    this.style('--clinic-qrcode-title-font-size', this.config?.titleFontSize, ClinicConfigThemeTransforms.toPx);
    this.style('--clinic-qrcode-title-font-weight', this.config?.titleFontWeight);

    this.style('--clinic-qrcode-caption-text-color', this.config?.captionTextColor);
    this.style('--clinic-qrcode-caption-font-size', this.config?.captionFontSize, ClinicConfigThemeTransforms.toPx);
    this.style('--clinic-qrcode-caption-font-weight', this.config?.captionFontWeight);
  }

  protected style(property: string, value?: unknown, ...transforms: CssStyleTransform[]) {
    if (value === undefined) {
      return;
    }

    const style: CssStyleVar = {
      name: property,
      value: transforms.length > 0 ? transforms.map(transform => transform(value)) : value
    };

    if (style.value) {
      this.elementRef.nativeElement.style.setProperty(style.name, style.value);
    } else {
      this.elementRef.nativeElement.style.removeProperty(style.name);
    }
  }
}
