import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EnvironmentSelectors } from '@shareview/configuration';
import { from, map, Observable, switchMap } from 'rxjs';
import { AuthTokenService } from '../services';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  public constructor(private authTokenService: AuthTokenService,
                     private store: Store) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const serviceUrls = this.store.selectSnapshot(EnvironmentSelectors.serviceUrls);

    if (!serviceUrls.find(url => request.url.startsWith(url))) {
      return next.handle(request);
    }

    const token$ = this.authTokenService.getToken();

    return from(token$)
      .pipe(
        map(token => `Bearer ${token}`),
        map(header => request.clone({ headers: request.headers.append('Authorization', header) })),
        switchMap(req => next.handle(req))
      );
  }
}
