import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { MediaClientV1 } from '@shareview/media-api-client';
import { firstValueFrom } from 'rxjs';
import { Category } from '../models';
import { VideoLibraryActions } from './video-library.actions';
import { DEFAULT_STATE, VideoLibraryStateModel } from './video-library.state-model';
import { STATE_TOKEN } from './video-library.state-token';

type Context = StateContext<VideoLibraryStateModel>;

@State<VideoLibraryStateModel>({
  name: STATE_TOKEN,
  defaults: DEFAULT_STATE
})
@Injectable()
export class VideoLibraryState {

  public constructor(private mediaClient: MediaClientV1) {
  }

  @Action(VideoLibraryActions.GetCategories)
  public async getCategories(context: Context, { libraryId }: VideoLibraryActions.GetCategories) {
    const categories$ = this.mediaClient.getCategoriesForLibrary(libraryId);

    context.patchState({ loading: true });

    try {
      const result = await firstValueFrom(categories$);
      const categories = result
        .filter(item => item.published ?? false)
        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        .map(item => {
          const category: Category = {
            id: item.id ?? '',
            name: item.name ?? '',
            videoCount: item.videoCount ?? 0
          };

          return category;
        });

      context.patchState({ categories });
    } finally {
      context.patchState({ loading: false });
    }
  }

  @Action(VideoLibraryActions.GetVideosForCategory)
  public async getVideosForCategory(context: Context, { categoryId }: VideoLibraryActions.GetVideosForCategory) {
    const state = context.getState();

    if (categoryId === state.categoryId) {
      return;
    }

    const videos$ = this.mediaClient.getCategoryVideos(categoryId);

    context.patchState({ videos: [], loading: true });

    try {
      const result = await firstValueFrom(videos$);

      context.patchState({ videos: result, categoryId: categoryId });
    } finally {
      context.patchState({ loading: false });
    }
  }

  @Action(VideoLibraryActions.Reset)
  public reset(context: Context) {
    context.patchState(DEFAULT_STATE);
  }
}
