import { LayoutPeer } from './layout-peer';

export class LayoutManager {
  private readonly _peers: Record<string, HTMLDivElement> = {};

  private _root: HTMLDivElement | null = null;
  private _maxPeers = 0;
  private _maxPeersSqrt = 0;
  private _emptyPlaceholder?: HTMLDivElement;

  public placeholderMessage = 'Please wait for someone to join';
  public showPlaceholderMessage = true;

  public constructor() {
    this.maxPeers = 9;
  }

  protected get maxPeers(): number {
    return this._maxPeers;
  }

  protected set maxPeers(max: number) {
    this._maxPeers = max;
    this._maxPeersSqrt = Math.ceil(Math.sqrt(max));
  }

  public attach(root: HTMLDivElement): void {
    this._root = root;
    this._root.style.background = 'black';
    this._root.style.boxSizing = 'border-box';
    this._root.style.minWidth = '0';
    this._root.style.display = 'grid';
    this._root.style.gridAutoFlow = 'row';
    this._root.style.alignItems = 'center';
    this._root.style.justifyContent = 'center';

    this.addEmptyPlaceholder();
  }

  public detach(): void {
    this.removeAllPeers();

    this._root = null;
  }

  public addPeer(peerId: string): LayoutPeer {
    if (!this._root) {
      throw new Error('Root has not been attached');
    }

    const container = document.createElement('div');

    container.id = `video-card-peer-${peerId}`;
    container.className = 'video-card';
    container.style.width = '100%';
    container.style.height = '100%';
    container.style.background = 'gray';
    container.style.position = 'relative';

    if (this.peerCount > this.maxPeers - 1) {
      container.style.display = 'none';
    }

    this._peers[peerId] = container;

    this.removeEmptyPlaceholder();
    this.updateRootGridDimensions(this.peerCount);
    this._root.appendChild(container);

    const video = document.createElement('video');

    video.style.width = '100%';
    video.style.height = '100%';
    video.style.position = 'absolute';
    video.style.top = '0';
    video.style.bottom = '0';
    video.style.left = '0';
    video.style.right = '0';
    video.autoplay = true;
    video.style.objectFit = 'cover';
    video.style.objectPosition = 'center center';

    container.appendChild(video);

    return { container, video };
  }

  public removePeer(peerId: string): void {
    const peer = this.getPeerById(peerId);

    if (!peer) {
      return;
    }

    this._root?.removeChild(peer);
    this.updateRootGridDimensions(this.peerCount - 1);

    delete this._peers[peerId];

    if (this.peerCount < 1) {
      this.addEmptyPlaceholder();
    }
  }

  private removeAllPeers(): void {
    for (const peerId of this.peerIds) {
      this.removePeer(peerId);
    }

    this.addEmptyPlaceholder();
  }

  public get peerIds(): string[] {
    return Object.keys(this._peers);
  }

  public get peerCount(): number {
    return Object.keys(this._peers).length;
  }

  public getPeerById(peerId: string): HTMLDivElement | null {
    if (Object.keys(this._peers).includes(peerId)) {
      return this._peers[peerId];
    }

    return null;
  }

  private updateRootGridDimensions(count: number): void {
    if (!this._root) {
      return;
    }

    const containerCount = Math.ceil(Math.sqrt(count));
    const rowColCount = Math.min(Math.max(containerCount, 1), this._maxPeersSqrt);

    this._root.style.gridTemplateColumns = `repeat(${rowColCount}, 1fr)`;
  }

  private addEmptyPlaceholder(): void {
    if (!this._root || !this.showPlaceholderMessage) {
      return;
    }

    this.removeEmptyPlaceholder();

    const placeholder = document.createElement('div');

    placeholder.style.width = '100%';
    placeholder.style.height = '100%';
    placeholder.style.position = 'absolute';
    placeholder.style.top = '0';
    placeholder.style.bottom = '0';
    placeholder.style.left = '0';
    placeholder.style.right = '0';
    placeholder.style.display = 'flex';
    placeholder.style.flexDirection = 'column';
    placeholder.style.alignItems = 'center';
    placeholder.style.justifyContent = 'center';

    const message = document.createElement('p');

    message.style.color = 'white';
    message.style.fontSize = '32px';

    message.append(this.placeholderMessage);

    placeholder.appendChild(message);
    this._root.appendChild(placeholder);

    this._emptyPlaceholder = placeholder;
  }

  private removeEmptyPlaceholder(): void {
    if (!this._root || !this._emptyPlaceholder) {
      return;
    }

    this._root.removeChild(this._emptyPlaceholder);
    this._emptyPlaceholder = undefined;
  }
}
