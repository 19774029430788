import { CommonModule } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TrackByFunction, ViewChild
} from '@angular/core';
import { FocusableComponent } from '@shareview/shared/focusable';
import { NgScrollbar, ScrollViewport } from 'ngx-scrollbar';
import { Category } from '../../models';
import { CategoryButtonComponent } from '../category-button';

@Component({
  selector: 'sv-categories-buttons',
  standalone: true,
  imports: [CommonModule, CategoryButtonComponent, NgScrollbar, ScrollViewport],
  templateUrl: './categories-buttons.component.html',
  styleUrls: ['./categories-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => CategoriesButtonsComponent) }]
})
export class CategoriesButtonsComponent extends FocusableComponent implements AfterContentChecked {
  @Input()
  public selectedCategoryId: string | null = null;

  @Output()
  public categorySelect = new EventEmitter<string>();

  @ViewChild('content')
  private _contentDiv!: ElementRef;

  private _prevCategories: Category[] = [];
  private _categories: Category[] = [];

  public constructor(private elementRef: ElementRef) {
    super();

    this.focusScrollOrientation = 'horizontal';
  }

  @Input()
  public get categories(): Category[] {
    return this._categories;
  }

  public set categories(value: Category[]) {
    this._categories = value;
    this.changeDetectorRef.detectChanges();
  }

  protected get trackByFn(): TrackByFunction<Category> {
    return (_, item) => item.id;
  }

  public ngAfterContentChecked(): void {
    if (this._prevCategories !== this._categories || this._prevCategories.length !== this._categories.length) {
      this._prevCategories = this._categories;
      this.currentFocusChildIndex = this._categories.length > 0 ? 0 : -1;

      this.updateCategorySelected();
    }
  }

  protected override onFocusedItemChanged(component: FocusableComponent | undefined, element: ElementRef | undefined): void {
    this.updateCategorySelected();

    if (!component || !element) {
      return;
    }

    const parentScrollLeft = this._contentDiv.nativeElement.scrollLeft;
    const overflowEnd = element.nativeElement.offsetLeft + element.nativeElement.offsetWidth - this._contentDiv.nativeElement.offsetLeft - parentScrollLeft > this._contentDiv.nativeElement.offsetWidth;
    const overflowStart = element.nativeElement.offsetLeft - this._contentDiv.nativeElement.offsetLeft - parentScrollLeft < 0;

    if (overflowEnd) {
      this._contentDiv.nativeElement.scroll({ left: element.nativeElement.offsetLeft + element.nativeElement.offsetWidth - this._contentDiv.nativeElement.offsetWidth, behavior: 'smooth' });
    } else if (overflowStart) {
      this._contentDiv.nativeElement.scroll({ left: element.nativeElement.offsetLeft, behavior: 'smooth' })
    }
  }

  protected categoryButtonClicked(button: CategoryButtonComponent): void {
    const index = this.focusableChildren.toArray().indexOf(button);

    if (index < 0) {
      return;
    }

    this.currentFocusChildIndex = index;
  }

  private updateCategorySelected(): void {
    const category = this.categories[this.currentFocusChildIndex];

    if (category) {
      this.categorySelect.emit(category.id);
    }
  }
}
