import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClinicConfigThemeOutletDirective } from './directives/clinic-config-theme-outlet.directive';

@NgModule({
  declarations: [
    ClinicConfigThemeOutletDirective
  ],
  imports: [CommonModule],
  exports: [
    ClinicConfigThemeOutletDirective
  ]
})
export class ClinicConfigModule {
}
