import { WidgetPosition } from '@shareview/sessionmanager-api-client';
import { CastLayout } from '../types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CastingActions {
  const NAMESPACE = '[Casting]';

  export class CreateSession {
    public static readonly type = `${NAMESPACE} Create Session`;

    public constructor(public sessionId: string, public layout: CastLayout, public widgets: WidgetPosition[]) {
    }
  }

  export class SetLayout {
    public static readonly type = `${NAMESPACE} Set Layout`;

    public constructor(public layout: CastLayout, public widgets: WidgetPosition[]) {
    }
  }

  export class Reset {
    public static readonly type = `${NAMESPACE} Reset`;
  }
}
