import { Injectable } from '@angular/core';
import {
  ClinicConfig,
  ClinicConfigThemeService,
  ClinicConfigThemeTransforms,
  StyleVars
} from '@shareview/clinic/config';

@Injectable()
export class ClinicHeroConfigThemeService extends ClinicConfigThemeService {

  public applyThemeConfig(config: ClinicConfig | null): StyleVars {
    const homeConfig = config?.home;
    const heroConfig = homeConfig?.hero;

    return [
      this.style('--clinic-hero-background-color', heroConfig?.backgroundColor),

      this.style('--clinic-hero-text-color', heroConfig?.textColor),
      this.style('--clinic-hero-font-size', heroConfig?.fontSize, ClinicConfigThemeTransforms.toPx),
      this.style('--clinic-hero-font-weight', heroConfig?.fontWeight)
    ];
  }
}
