<span class="title">{{ title }}</span>

<qr-code [centerImageSize]="centerImageSize"
         [centerImageSrc]="centerImageUrl"
         [darkColor]="foregroundColor"
         [errorCorrectionLevel]="errorCorrectionLevel"
         [lightColor]="backgroundColor"
         [size]="size"
         [value]="url">
</qr-code>

<span class="caption"><em>{{ caption }}</em></span>
