import { Selector } from '@ngxs/store';
import { OnboardingState } from './onboarding.state';
import { OnboardingStateModel } from './onboarding.state-model';

export class OnboardingSelectors {

  @Selector([OnboardingState])
  public static code(state: OnboardingStateModel): string | null {
    return state.code;
  }
}
