<div class="container" fxLayout="column" fxLayoutAlign="center end" fxLayoutGap="8px">

  <ng-container *ngFor="let item of items; index as i; trackBy: trackByCategoryVideos">
    <div class="logo-row" fxLayout="row" fxLayoutAlign="end center">
      <sv-video-label (click)="clicked(item)" [active]="currentFocusChildIndex === i" [item]="item"></sv-video-label>
    </div>
  </ng-container>

  <span fxFlex></span>

<!--  <ng-container *ngIf="brandLogoUrl">-->
<!--    <div class="logo-row" fxLayout="row">-->
<!--      <img [src]="brandLogoUrl" alt="ShareSafe logo" />-->
<!--    </div>-->
<!--  </ng-container>-->

</div>
