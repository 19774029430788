import { AfterViewInit, Component, OnDestroy, OnInit, inject, ApplicationRef } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngxs/store';
import { ScreensaverService } from '@shareview/clinic/screensaver';
import { EnvironmentActions } from '@shareview/configuration';
import { CastingActions, CastingHubService, CastingSelectors, CastLayout } from '@shareview/features/casting';
import { BaseComponent } from '@shareview/shared/components';
import { TimeService } from '@shareview/shared/services';
import { filter, from, map, switchMap } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    RouterOutlet
  ],
  selector: 'shareview-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    CastingHubService,
    ScreensaverService,
    TimeService
    // TODO Move to app.config?
    // { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService },
  ]
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  private applicationRef = inject(ApplicationRef);
  private castingHubService = inject(CastingHubService);
  private router = inject(Router);
  private store = inject(Store);

  public ngOnInit(): void {
    // TODO Move to Tizen specific service?
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.applicationRef.tick();
      }
    });

    // TODO Remove this for push
    // this.store.dispatch(new EnvironmentActions.ChangeEnvironment('local'));

    this.store.dispatch(new CastingActions.Reset());

    this.subscription = this.castingHubService.sessionCreated$
      .pipe(
        map(message => new CastingActions.CreateSession(message.sessionId, message.layout as CastLayout, message.widgets)),
        switchMap(action => this.store.dispatch(action)),
        map(() => this.castingHubService.join()),
        switchMap(promise => from(promise))
      ).subscribe();

    this.subscription = this.castingHubService.beginSession$
      .pipe(
        switchMap(() => this.store.selectOnce(CastingSelectors.sessionId)),
        filter(sessionId => !!sessionId && sessionId.length > 0),
        map(sessionId => this.router.navigate(['/cast', sessionId])),
        switchMap(promise => from(promise))
      ).subscribe();

    this.subscription = this.castingHubService.endSession$
      .pipe(
        map(() => this.router.navigate(['../'])),
        switchMap(promise => from(promise))
      ).subscribe();

    this.subscription = this.castingHubService.reset$
      .pipe(
        map(() => this.castingHubService.leave()),
        switchMap(promise => from(promise)),
        map(() => this.castingHubService.createSession('5f9f1b0b-1b2b-4b01-5385-4a2790623c49', '00000000-0000-0000-0000-000000000000')),
        switchMap(promise => from(promise)),
        switchMap(() => this.castingHubService.join())
      ).subscribe();
  }

  public async ngAfterViewInit(): Promise<void> {
    const connect = await this.castingHubService.connect();

    if (connect) {
      await this.castingHubService.createSession('5f9f1b0b-1b2b-4b01-5385-4a2790623c49', '00000000-0000-0000-0000-000000000000');
      await this.castingHubService.join();
    }
  }

  public override async ngOnDestroy(): Promise<void> {
    await this.castingHubService.leave();
    await this.castingHubService.disconnect();

    console.log('disconnected')

    super.ngOnDestroy();
  }
}
