import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription, TeardownLogic } from 'rxjs';

@Component({
  template: ''
})
export abstract class BaseComponent implements OnDestroy {
  private readonly _onDestroySubject = new Subject<void>();
  private readonly _subscription = new Subscription();

  protected get onDestroy$(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  protected set subscription(teardownLogic: TeardownLogic) {
    this._subscription.add(teardownLogic);
  }

  public ngOnDestroy(): void {
    this._onDestroySubject.next();
    this._onDestroySubject.complete();

    this._subscription.unsubscribe();
  }
}
