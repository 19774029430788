import { Directive, HostBinding, Input } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { ClinicToolbarBackgroundConfig, ClinicToolbarConfig } from '../../models';

const DEFAULT_HEIGHT = 108;
const DEFAULT_BACKGROUND_COLOR = 'rgb(18, 155, 194)';
const DEFAULT_BACKGROUND_GRADIENT_ANGLE = 270;
const DEFAULT_BACKGROUND_GRADIENT_STOPS = [
  { color: 'rgb(18, 155, 194)', stop: 30 },
  { color: 'rgb(17,  44,  85)', stop: 60 }
];

@Directive()
export class ClinicToolbarComponentStyle {
  @HostBinding('style.height.px')
  private height!: SafeStyle;

  protected constructor() {
    this.config = { items: [] };
  }

  private _config?: ClinicToolbarConfig;

  public get config(): ClinicToolbarConfig | undefined {
    return this._config;
  }

  @Input()
  public set config(value: ClinicToolbarConfig | undefined) {
    this._config = value;

    this.height = value?.height ?? DEFAULT_HEIGHT;
    this.background = value?.background;
  }

  @HostBinding('style.background')
  private _background!: SafeStyle;

  private set background(value: ClinicToolbarBackgroundConfig | undefined) {
    const solid = value?.color ?? DEFAULT_BACKGROUND_COLOR;
    const angle = value?.gradient?.angle ?? DEFAULT_BACKGROUND_GRADIENT_ANGLE;
    const stops = (value?.gradient?.stops ?? DEFAULT_BACKGROUND_GRADIENT_STOPS).map(v => `${v.color} ${v.stop}%`).join(',');
    const gradient = `linear-gradient(${angle}deg, ${stops})`;

    this._background = `${solid} ${gradient}`;
  }
}
