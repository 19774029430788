<ng-container *ngIf="(layoutClass$ | async) ?? '1' as layoutClass; else spinner">
  <div [ngClass]="layoutClass" class="grid">
    <ng-container *ngFor="let widget of widgets$ | async">
      <ng-container [ngSwitch]="widget.widget">
        <sv-casting-content *ngSwitchDefault [widget]="widget" class="quad"></sv-casting-content>
<!--        <sv-video-call-vonage *ngSwitchCase="'video-call'" class="quad"></sv-video-call-vonage>-->
        <sv-video-call-webrtc *ngSwitchCase="'video-call'" class="quad"></sv-video-call-webrtc>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #spinner>
  <sv-loading-spinner></sv-loading-spinner>
</ng-template>
