import { Pipe, PipeTransform } from '@angular/core';
import { ExperienceBrandType } from './experience-brand.type';

@Pipe({
  name: 'experienceBrandFormat',
  standalone: true,
})
export class ExperienceBrandFormatPipe implements PipeTransform {
  transform(value: ExperienceBrandType): string {
    switch (value) {
      case 'clinical':
        return 'CX';

      case 'patient':
        return 'PX';

      default:
        throw new Error('Invalid experience brand type');
    }
  }
}
