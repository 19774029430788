import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  QueryList,
  TrackByFunction
} from '@angular/core';
import { ClinicConfigService } from '@shareview/clinic/config';
import { VideoCategoryModel } from '@shareview/media-api-client';
import { FocusableComponent, FocusableViewChildrenComponent } from '@shareview/shared/focusable';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs';
import { VideoLabelComponent } from '../video-label/video-label.component';

@Component({
  selector: 'sv-label-column',
  templateUrl: './label-column.component.html',
  styleUrls: ['./label-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => LabelColumnComponent) }]
})
export class LabelColumnComponent extends FocusableViewChildrenComponent<VideoLabelComponent, VideoCategoryModel> implements AfterViewInit {

  public constructor(private configService: ClinicConfigService) {
    super();
  }

  public get trackByCategoryVideos(): TrackByFunction<VideoCategoryModel> {
    return (index, item) => item.name;
  }

  public get brandLogoUrl(): string | undefined {
    return this.configService.clinicConfig?.home?.brandLogoUrl;
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.focusableChildren.changes.pipe(
      takeUntil(this.onDestroy$),
      filter(() => this.isFocused),
      map(changes => changes as QueryList<VideoLabelComponent>),
      map(changes => changes.toArray()),
      distinctUntilChanged()
    ).subscribe(() => this.currentFocusChildIndex = 0);
  }

  public override handleKeyPress(event: KeyboardEvent): boolean {
    switch (event.key) {
      case 'ArrowUp':
        this.decrementChildFocus();
        break;

      case 'ArrowDown':
        this.incrementChildFocus();
        break;

      default:
        return false;
    }

    return true;
  }

  public clicked(item: VideoCategoryModel): void {
    const children = (this.focusableChildren as QueryList<VideoLabelComponent>).toArray();

    this.currentFocusChildIndex = children.findIndex(child => child.item === item);
  }
}
