// This file can be replaced during package by using the `fileReplacements` array.
// `ng package` replaces `environment.ts` with `environment.qa.ts`.
// The list of file replacements can be found in `angular.json`.

const clinicConfig: ClinicConfig = {
  // clinicId: '5F9F1B0B-1B2B-4B01-5385-4A2790623C49',
  clinicId: '0E667AD5-E76B-4077-88AA-02DCD29F07A4',
  style: {
    // backgroundColor: '#19203A'
  },
  toolbar: {
    items: [
      {
        type: ClinicToolbarItemType.image,
        imageUrl: 'assets/logoVert2.png'
      } as ClinicToolbarImageItem,
      {
        type: ClinicToolbarItemType.text,
        textColor: '#FFFFFF',
        fontSize: 30,
        fontWeight: 500,
        text: 'Welcome to Franklin Primary Health Center'
      } as ClinicToolbarTextItem,
      {
        type: ClinicToolbarItemType.text,
        text: '    '
      },
      {
        type: ClinicToolbarItemType.image,
        imageUrl: 'assets/Aphca_Logo.png'
      } as ClinicToolbarImageItem
    ]
  },
  home: {
    clinicQrCode: {
      title: 'ShareView',
      caption: 'For Clinicians Only',
      // url: '0849bc83-82af-4ca7-9b4f-8a2c6ac79821',
      qrCodeSize: 180,
      errorCorrectionLevel: 'L'
    },
    patientQrCode: {
      title: 'How are we doing?',
      caption: 'Rate us on Google',
      url: 'https://gulfortho.com/orthopaedics-surgery-testimonials-mobile-al/',
      qrCodeSize: 180,
      errorCorrectionLevel: 'L',
      // backgroundColor: '#c8e6c9'
    },
    hero: {
      thumbnailUrl: 'assets/Website-Banners-1.png'
    },
    // backgroundImageUrl: 'https://media.services.shareview.dev/v1/assets/images/4a548bc5-0011-4d9b-8ab0-dc76b92c9056',
    brandLogoUrl: 'assets/logoVert.png'
  },
  videos: {
    posterImageUrl: 'https://media.services.shareview.dev/v1/assets/images/fef24b68-169b-4114-946a-0c78d88607f7'
  }
  // screensaver: {
  //   videoUrl: 'https://player.vimeo.com/progressive_redirect/playback/796763596/rendition/1080p/file.mp4?loc=external&signature=3a07eb3f8b360d39e6e7bbb5f7d2a1d9d0fccd8470481f2953751172a034a504',
  //   mimeType: 'video/mp4',
  //   timeout: 5
  // }
};

export const environment = {
  production: false,
  baseUrl: 'https://media.services.myshareview.com',
  commandUrl: 'wss://command.services.shareview.dev',
  clinicId: clinicConfig.clinicId,
  applicationInsights: 'InstrumentationKey=5d9a3c40-466c-4d0b-ade0-518f7293be4b;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
  refreshInterval: 15 * 60 * 1000, // 15 minutes
  clinicConfig: clinicConfig
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';
import { ClinicConfig } from '@shareview/clinic/config';
import { ClinicToolbarImageItem, ClinicToolbarItemType, ClinicToolbarTextItem } from '@shareview/clinic/toolbar'; // Included with Angular CLI.
