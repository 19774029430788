import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { CastingActions } from './casting.actions';
import { CastingStateModel, DEFAULT_STATE } from './casting.state-model';
import { CASTING_STATE_TOKEN } from './casting.state-token';

type Context = StateContext<CastingStateModel>;

@State<CastingStateModel>({
  name: CASTING_STATE_TOKEN,
  defaults: DEFAULT_STATE
})
@Injectable()
export class CastingState {

  @Action(CastingActions.CreateSession)
  public createSession(context: Context, { sessionId, layout, widgets }: CastingActions.CreateSession) {
    context.patchState({ sessionId, layout, widgets });
    console.log('sessionId', sessionId)
  }

  @Action(CastingActions.SetLayout)
  public setLayout(context: Context, { layout, widgets }: CastingActions.SetLayout) {
    context.patchState({ layout, widgets });
  }

  @Action(CastingActions.Reset)
  public reset(context: Context) {
    context.patchState(DEFAULT_STATE);
  }
}
