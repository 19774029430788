import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TextComponentStyle } from './text.component.style';

@Component({
  selector: 'sv-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextComponent extends TextComponentStyle {

  public get text(): string {
    return this.value?.text ?? '';
  }
}
