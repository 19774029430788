import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ToolbarTimeComponent } from '../toolbar-time';

@Component({
  selector: 'sv-toolbar',
  standalone: true,
  imports: [CommonModule, ToolbarTimeComponent],
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent {

}
