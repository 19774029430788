import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import { ClinicConfigService, ClinicConfigThemeTransforms } from '@shareview/clinic/config';
import { ClinicVideoPlayerOverlayService } from '@shareview/clinic/services';
import { VideoLibraryModel, VideoModel } from '@shareview/media-api-client';
import {
  FocusableComponent,
  FocusableViewChildrenComponent,
  ScrollableFocusableComponent
} from '@shareview/shared/focusable';
import { VideoTileComponent } from '../video-tile/video-tile.component';

@Component({
  selector: 'sv-video-row',
  templateUrl: './video-row.component.html',
  styleUrls: ['./video-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('indicator', [
      transition('* => true', [
        style({ opacity: 0 }),
        animate('250ms 500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('spinner', [
      transition('* => true', [
        style({ opacity: 0 }),
        animate('250ms 250ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('card', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms 250ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ],
  providers: [{ provide: FocusableComponent, useExisting: forwardRef(() => VideoRowComponent) }]
})
export class VideoRowComponent extends FocusableViewChildrenComponent<VideoTileComponent, VideoModel> implements OnInit {
  @Input()
  public library!: VideoLibraryModel;

  @ViewChild(ScrollableFocusableComponent, { static: true })
  private _scrollView!: ScrollableFocusableComponent;

  public constructor(private configService: ClinicConfigService,
                     private playerOverlayService: ClinicVideoPlayerOverlayService) {
    super();
  }

  public override get selectedItem(): VideoModel | undefined {
    return this.items[this._scrollView.currentFocusChildIndex];
  }

  public get trackByCategoryVideo(): TrackByFunction<VideoModel> {
    return (index, item) => item.id;
  }

  public get cardGap(): string {
    const gap = this.configService.clinicConfig?.home?.videoLibrary?.tiles?.cardGap ?? 0;

    return ClinicConfigThemeTransforms.toPx(gap);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
  }

  public override handleKeyPress(event: KeyboardEvent): boolean {
    if (this.handleChildKeyPress(event)) {
      return true;
    }

    switch (event.key) {
      case 'Enter':
        this.openVideo(this.selectedItem);
        return true;

      default:
        return false;
    }
  }

  private openVideo(video?: VideoModel): void {
    this.playerOverlayService.openVideo(this, this.library, video);
  }
}
