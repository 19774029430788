import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EnvironmentSelectors } from '@shareview/configuration';
import jwtDecode from 'jwt-decode';
import { firstValueFrom } from 'rxjs';
import { DiscoveryDocument, JwtToken, TokenResponse } from '../../models';

@Injectable()
export class AuthTokenService {
  private _discoveryDocument?: DiscoveryDocument;
  private _tokens?: TokenResponse;

  public constructor(private httpClient: HttpClient,
                     private store: Store) {
  }

  public async getToken(): Promise<string | null> {
    const config = this.store.selectSnapshot(EnvironmentSelectors.authentication);

    if (!config) {
      return null;
    }

    if (!this.isTokenValid() || !this._tokens?.access_token) {
      const discoveryDocument = await this.getDiscoveryDocument();

      if (!discoveryDocument) {
        return null;
      }

      const headers = new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded');

      const payload = new HttpParams()
        .set('grant_type', 'client_credentials')
        .set('client_id', config.clientId)
        .set('client_secret', config.clientSecret)
        .set('scope', config.scope);

      const tokens$ = this.httpClient.post<TokenResponse>(discoveryDocument.token_endpoint, payload.toString(), { headers: headers });

      this._tokens = await firstValueFrom(tokens$);
    }

    return this._tokens?.access_token;
  }

  private isTokenValid(): boolean {
    if (!this._tokens?.access_token) {
      return false;
    }

    const expWindowPad = Math.round(this._tokens.expires_in * 0.15) * 1000;
    const token = jwtDecode<JwtToken>(this._tokens.access_token);
    const tokenExp = token.exp * 1000;
    const now = new Date();

    return tokenExp - expWindowPad >= now.getTime();
  }

  private async getDiscoveryDocument(): Promise<DiscoveryDocument | null> {
    const config = this.store.selectSnapshot(EnvironmentSelectors.authentication);

    if (!config) {
      return null;
    }

    if (this._discoveryDocument) {
      return this._discoveryDocument;
    }

    const document$ = this.httpClient.get<DiscoveryDocument>(`${config.issuer}/.well-known/openid-configuration`);

    this._discoveryDocument = await firstValueFrom(document$);

    return this._discoveryDocument;
  }
}
