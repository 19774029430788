import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ImageComponentStyle } from './image.component.style';

@Component({
  selector: 'sv-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent extends ImageComponentStyle {

  public get imageUrl(): string | undefined {
    return this.value?.imageUrl;
  }
}
