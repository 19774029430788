import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ControlButtonComponent } from './components/control-button/control-button.component';
import { ControlIndicatorComponent } from './components/control-indicator/control-indicator.component';
import { PlayerControlsComponent } from './components/player-controls/player-controls.component';
import { ProgressTimePipe } from './pipes/progress-time.pipe';

@NgModule({
  declarations: [
    ControlButtonComponent,
    ControlIndicatorComponent,
    PlayerControlsComponent,
    ProgressTimePipe
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatProgressBarModule,
    InlineSVGModule
  ],
  exports: [
    ControlButtonComponent,
    ControlIndicatorComponent,
    PlayerControlsComponent
  ]
})
export class VideoPlayerControlsModule {
}
