import { VideoModel } from '@shareview/media-api-client';
import { Category } from '../models';

export interface VideoLibraryStateModel {
  categoryId: string | null;
  categories: Category[];
  videos: VideoModel[];
  loading: boolean;
}

export const DEFAULT_STATE: VideoLibraryStateModel = {
  categoryId: null,
  categories: [],
  videos: [],
  loading: false
};
