import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';

@Injectable()
export class TimeService implements OnDestroy {
  private readonly _timestamp = new BehaviorSubject<Date>(new Date());
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _timer?: any;

  public constructor() {
    this._timestamp.next(new Date());

    setTimeout(() => {
      this._timer = setInterval(() => this._timestamp.next(new Date()), 1000 * 60);
    }, 1000 - new Date().getMilliseconds());
  }

  public get timestamp$(): Observable<Date> {
    return this._timestamp.asObservable()
      .pipe(
        distinctUntilChanged()
      );
  }

  public ngOnDestroy(): void {
    this._timestamp.complete();

    if (this._timer) {
      clearInterval(this._timer);

      this._timer = undefined;
    }
  }
}
