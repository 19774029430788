import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { EnvironmentActions } from './environment.actions';
import { DEFAULT_STATE, EnvironmentStateModel } from './environment.state-model';
import { ENVIRONMENT_STATE_TOKEN } from './environment.state-token';

type Context = StateContext<EnvironmentStateModel>;

@State<EnvironmentStateModel>({
  name: ENVIRONMENT_STATE_TOKEN,
  defaults: DEFAULT_STATE
})
@Injectable()
export class EnvironmentState {

  @Action(EnvironmentActions.LoadEnvironments)
  public loadEnvironments(context: Context, { config }: EnvironmentActions.LoadEnvironments) {
    context.patchState({
      defaultEnvironmentId: config.defaultEnvironmentId,
      currentEnvironmentId: config.defaultEnvironmentId,
      environments: config.environments
    });
  }

  @Action(EnvironmentActions.ChangeEnvironment)
  public changeEnvironment(context: Context, { environmentId }: EnvironmentActions.ChangeEnvironment) {
    const state = context.getState();
    const exists = !!state.environments.find(env => env.id === environmentId);

    if (!exists) {
      // TODO Do something useful with this error.
      console.error(`Environment with ID '${environmentId}' does not exist!`);
      return;
    }

    context.patchState({ currentEnvironmentId: environmentId });
  }

  @Action(EnvironmentActions.Reset)
  public reset(context: Context) {
    context.patchState(DEFAULT_STATE);
  }
}
