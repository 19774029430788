import { createSelector, Selector } from '@ngxs/store';
import { Environment } from '../../models';
import { EnvironmentName } from '../../models/environment-name';
import { EnvironmentState } from './environment.state';
import { EnvironmentStateModel } from './environment.state-model';

export class EnvironmentSelectors {

  @Selector([EnvironmentState])
  public static environmentNames(state: EnvironmentStateModel): EnvironmentName[] {
    return state.environments.map(env => {
      return {
        id: env.id,
        name: env.name
      } as EnvironmentName;
    });
  }

  @Selector([EnvironmentState, EnvironmentSelectors.environmentId])
  public static environment(state: EnvironmentStateModel, environmentId: string): Environment | null {
    return state.environments.find(environment => environment.id === environmentId) ?? null;
  }

  @Selector([EnvironmentState])
  public static currentEnvironment(state: EnvironmentStateModel): Environment | null {
    const environmentId = state.currentEnvironmentId ?? state.defaultEnvironmentId;

    return state.environments.find(environment => environment.id === environmentId) ?? null;
  }

  @Selector([EnvironmentSelectors.environment])
  public static authentication(environment: Environment | null) {
    return environment?.authentication;
  }

  public static serviceUrl(serviceName: string): (environment: (Environment | null)) => string | null {
    return createSelector([EnvironmentSelectors.environment], (environment: Environment | null) => {
      return environment?.services[serviceName] ?? null;
    });
  }

  @Selector([EnvironmentSelectors.currentEnvironment])
  public static serviceUrls(environment: Environment | null): string[] {
    if (!environment) {
      return [];
    }

    const keys = Object.keys(environment.services);
    const urls: string[] = [];

    for (const key of keys) {
      urls.push(environment.services[key]);
    }

    return urls;
  }

  @Selector([EnvironmentState])
  private static environmentId(state: EnvironmentStateModel): string {
    return state.currentEnvironmentId ?? state.defaultEnvironmentId ?? 'dev'; // TODO Change to prod when production is ready.
  }
}
