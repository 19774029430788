import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthTokenService } from './services';

@NgModule({
  imports: [CommonModule]
})
export class SharedAuthenticationModule {

  public static forRoot(): ModuleWithProviders<SharedAuthenticationModule> {
    return {
      ngModule: SharedAuthenticationModule,
      providers: [
        AuthTokenService
      ]
    };
  }
}
