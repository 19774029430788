import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DeviceActions } from '@shareview/configuration';
import { CastingSelectors } from '@shareview/features/casting';
import { VideoModel } from '@shareview/media-api-client';
import { FocusRootComponent } from '@shareview/shared/focusable';
import { VideoMetadata, VideoPlayerModule, VideoPlayerService } from '@shareview/video/player';
import { NgLetModule } from 'ng-let';
import { QrCodeModule } from 'ng-qrcode';
import { Observable, take } from 'rxjs';
import {
  CategoriesButtonsComponent,
  ToolbarComponent,
  VideoCardComponent,
  VideoCardRowComponent
} from '../../components';
import { Category } from '../../models';
import { VideoLibraryActions, VideoLibrarySelectors } from '../../state';

@Component({
  selector: 'sv-video-library-2-page',
  standalone: true,
  imports: [CommonModule, ToolbarComponent, VideoCardComponent, QrCodeModule, CategoriesButtonsComponent, VideoCardRowComponent, NgLetModule, VideoPlayerModule],
  templateUrl: './video-library-2-page.component.html',
  styleUrls: ['./video-library-2-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
export class VideoLibrary2PageComponent extends FocusRootComponent implements OnInit {
  @Select(CastingSelectors.sessionId)
  protected sessionId$!: Observable<string | null>;

  @Select(VideoLibrarySelectors.categoryId)
  protected categoryId$!: Observable<string | null>;

  @Select(VideoLibrarySelectors.categories)
  protected categories$!: Observable<Category[]>;

  @Select(VideoLibrarySelectors.videos)
  protected videos$!: Observable<VideoModel[]>;

  @Select(VideoLibrarySelectors.loading)
  protected loading$!: Observable<boolean>;

  protected categories: Category[] = [];

  public constructor(private store: Store,
                     private videoPlayerService: VideoPlayerService) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    // TODO Complete onboarding so this can be removed!
    this.store.dispatch([
      new DeviceActions.SetClinicId('BB2772A1-A964-40E6-BBAF-0EB7CB6FFF16'),
      new VideoLibraryActions.GetCategories('BB2772A1-A964-40E6-BBAF-0EB7CB6FFF16')
    ]);
  }

  protected updateCategoryVideos(categoryId: string): void {
    this.store.dispatch(new VideoLibraryActions.GetVideosForCategory(categoryId));
  }

  protected videoSelected(video: VideoModel): void {
    const metadata: VideoMetadata = {
      videoUrl: video.videoUrl ?? '',
      mimeType: video.videoMimeType ?? ''
    };

    const playerRef = this.videoPlayerService.open(metadata);

    if (playerRef.instance) {
      this.pushFocusableInterceptor(playerRef.instance);
    }

    playerRef.afterClosed$.pipe(
      take(1)
    ).subscribe(() => {
      this.popFocusableInterceptor();
    });
  }
}
