import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClinicConfig } from '../models/clinic-config';

@Injectable({
  providedIn: 'root'
})
export class ClinicConfigService {
  private readonly _configSubject = new BehaviorSubject<ClinicConfig | null>(null);

  public get clinicConfig$(): Observable<ClinicConfig | null> {
    return this._configSubject.asObservable();
  }

  public get clinicConfig(): ClinicConfig | null {
    return this._configSubject.value;
  }

  public set clinicConfig(value: ClinicConfig | null) {
    this._configSubject.next(value);
  }
}
