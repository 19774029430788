<sv-experience-brand type="patient"></sv-experience-brand>
<sv-brand-header>Personalize My Experience</sv-brand-header>

<div class="wrapper">

  <div class="phone">
    <div class="phone-inner">
      <div class="icons">
        <img src="/assets/netflix-white.png" />
        <img src="/assets/youtube-tv-white.png" />
        <img src="/assets/instagram-white.png" />
<!--        <img src="/assets/more_horiz.png" />-->

        <div class="more-icon">
          <mat-icon>add</mat-icon>
          <span>More</span>
        </div>
      </div>

      <div class="instructions">
        <span>Scan the QR code to:</span>

        <ol>
          <li class="flex flex-col">
            <span>1. Download the</span>
            <span class="pl-5">ShareView app</span>
          </li>
          <li class="flex flex-col">
            <span>2. Cast your apps</span>
            <span class="pl-7">to the TV</span>
          </li>
        </ol>
      </div>

      <div class="qr-code">
        <div class="wrapper">
          <sv-color-rings-layout [centerSize]="280" [ringSize]="52">
            <div class="flex flex-col items-center">
              <qr-code [darkColor]="'#FFFFFFFF'" [lightColor]="'#00000000'" [value]="'https://shareview.com'"
                       size="180"></qr-code>
              <span class="caption">For Patients</span>
            </div>
          </sv-color-rings-layout>
        </div>
      </div>
    </div>
  </div>

</div>
