import { Injectable } from '@angular/core';
import {
  ClinicConfig,
  ClinicConfigThemeService,
  ClinicConfigThemeTransforms,
  StyleVars
} from '@shareview/clinic/config';

declare const tinycolor: any;

interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Injectable()
export class ClinicStylesConfigThemeService extends ClinicConfigThemeService {

  public applyThemeConfig(config: ClinicConfig | null): StyleVars {
    const styleConfig = config?.style;

    return [
      this.style('--clinic-border-radius', styleConfig?.borderRadius, ClinicConfigThemeTransforms.toPx),
      this.style('--clinic-background-color', styleConfig?.backgroundColor),
      ...this.materialThemePalette('theme-primary', styleConfig?.primaryColor),
      ...this.materialThemePalette('theme-accent', styleConfig?.accentColor),
      ...this.materialThemePalette('theme-warn', styleConfig?.warnColor)
    ];
  }

  private materialThemePalette(cssVarPrefix: string, hexColor?: string): StyleVars {
    if (!hexColor) {
      return [];
    }

    const palette = this.computeColors(hexColor);

    return palette.reduce<StyleVars>((result, color) => {
      return [
        ...result,
        this.style(`--${cssVarPrefix}-${color.name}`, color.hex),
        this.style(`--theme-primary-contrast-${color.name}`, color.darkContrast ? 'rgba(black, 0.87)' : 'white')
      ];
    }, []);
  }

  private computeColors(hex: string): Color[] {
    return [
      getColorObject(tinycolor(hex).lighten(52), '50'),
      getColorObject(tinycolor(hex).lighten(37), '100'),
      getColorObject(tinycolor(hex).lighten(26), '200'),
      getColorObject(tinycolor(hex).lighten(12), '300'),
      getColorObject(tinycolor(hex).lighten(6), '400'),
      getColorObject(tinycolor(hex), '500'),
      getColorObject(tinycolor(hex).darken(6), '600'),
      getColorObject(tinycolor(hex).darken(12), '700'),
      getColorObject(tinycolor(hex).darken(18), '800'),
      getColorObject(tinycolor(hex).darken(24), '900'),
      getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
      getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
      getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
      getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
    ];
  }
}

function getColorObject(value: any, name: string): Color {
  const c = tinycolor(value);

  return {
    name: name,
    hex: c.toHexString(),
    darkContrast: c.isLight()
  };
}
