const rtcConfig: RTCConfiguration = {
  iceServers: [
    {
      urls: ['stun:52.191.33.185:3478', 'turn:52.191.33.185:347'],
      username: 'shareview',
      credential: 'shareview'
    }
  ]
};

export interface ResizeMessage {
  width: number;
  height: number;
}

const assert_equals = (a: unknown, b: unknown, msg: string) => a === b || console.error(new Error(`${msg} : expected ${b} but got ${a}`));


export class Peer2 {
  private readonly _remoteStream: MediaStream;
  private readonly _peerConnection: RTCPeerConnection;
  private readonly _resizeDataChannel: RTCDataChannel;

  public onIce: (peerId: string, event: RTCIceCandidate) => void = () => console.error('onIce not handled.');
  public onDescription: (peerId: string, event: RTCSessionDescriptionInit | null) => void = () => console.error('onDescription not handled.');
  public onEnd: (peerId: string) => void = () => console.error('onEnd not handled.');
  public onResize: (peerId: string, message: ResizeMessage) => void = () => console.error('onResize not handled');
  public getContainerSize: (peerId: string) => ResizeMessage = () => ({ width: 1920 / 4, height: 1080 / 4 });

  private _makingOffer = false;
  private _ignoreOffer = false;
  private _answerPending = false;
  private _sentSize = false;

  public constructor(public peerId: string,
                     private polite: boolean) {
    console.log('polite', polite);

    this._remoteStream = new MediaStream();
    this._peerConnection = new RTCPeerConnection(rtcConfig);

    this._peerConnection.onicecandidate = async event => {
      if (event.candidate) {
        this.onIce(this.peerId, event.candidate);
      }
    };

    this._peerConnection.ontrack = event => {
      this._remoteStream.addTrack(event.track);
    };

    this._peerConnection.onnegotiationneeded = async () => {
      try {
        assert_equals(this._peerConnection.signalingState, 'stable', 'negotiationneeded always fires in stable state');

        if (this._peerConnection.signalingState !== 'stable') {
          return;
        }

        assert_equals(this._makingOffer, false, 'negotiationneeded not already in progress');

        this._makingOffer = true;

        await this._peerConnection.setLocalDescription();

        assert_equals(this._peerConnection.signalingState, 'have-local-offer', 'negotiationneeded not racing with onmessage');
        assert_equals(this._peerConnection.localDescription?.type, 'offer', 'negotiationneeded SLD worked');

        this.onDescription(this.peerId, this._peerConnection.localDescription);
      } catch (e) {
        console.error('eee', e);
        // fail(e);
      } finally {
        this._makingOffer = false;
      }
    };

    this._peerConnection.oniceconnectionstatechange = () => {
      if (this._peerConnection.iceConnectionState === 'failed') {
        this._peerConnection.restartIce();
      }
    };

    this._peerConnection.onconnectionstatechange = async () => {
      // console.log('connstate', this._peerConnection.connectionState);

      const connState = this._peerConnection.connectionState;

      if (connState === 'disconnected' || connState === 'closed') {
        this.end(true);
      } else if (connState === 'failed') {
        console.error('ice failure');
        // await this.restartIce();
        this._peerConnection.restartIce();
      }
    };

    this._resizeDataChannel = this._peerConnection.createDataChannel('resize');

    this._resizeDataChannel.onerror = (error) => {
      console.warn('resize dc error', error);
    };

    this._peerConnection.ondatachannel = (event) => {
      event.channel.onmessage = async (event: MessageEvent<string>) => {
        this.onResize(this.peerId, JSON.parse(event.data));

        if (!this._sentSize) {
          this.notifySizeChanged();
        }
      };
    };

    this._resizeDataChannel.onmessage = (event: MessageEvent<ResizeMessage>) => {
      if (!this._sentSize) {
        this.notifySizeChanged();
      }
    };

    this._resizeDataChannel.onopen = () => {
      this.notifySizeChanged();
    };

    this._resizeDataChannel.onclose = () => {
      console.log('dc close');
    };
  }

  public get remoteStream(): MediaStream {
    return this._remoteStream;
  }

  public async handleIce(candidate: RTCIceCandidate): Promise<void> {
    try {
      if (candidate) {
        await this._peerConnection.addIceCandidate(candidate);
      }
    } catch (error) {
      if (!this._ignoreOffer) {
        console.error('handleIce', error);
      }
    }
  }

  public async handleDescription(description: RTCSessionDescriptionInit | null): Promise<void> {
    if (!description) {
      return;
    }

    const isStable = this._peerConnection.signalingState == 'stable' || (this._peerConnection.signalingState == 'have-local-offer' && this._answerPending);

    this._ignoreOffer = description.type == 'offer' && !this.polite && (this._makingOffer || !isStable);

    if (this._ignoreOffer) {
      console.log('glare - ignoring offer');
      return;
    }
    this._answerPending = description.type === 'answer';

    try {
      await this._peerConnection.setRemoteDescription(description);
    } catch (e) {
      console.error(e);
      this._peerConnection.restartIce();
    }

    this._answerPending = false;

    if (description.type == 'offer') {
      assert_equals(this._peerConnection.signalingState, 'have-remote-offer', 'Remote offer');
      assert_equals(this._peerConnection.remoteDescription?.type, 'offer', 'SRD worked');

      try {
        await this._peerConnection.setLocalDescription();
      } catch (e) {
        console.error(e);
        return;
      }

      assert_equals(this._peerConnection.signalingState, 'stable', 'onmessage not racing with negotiationneeded');
      assert_equals(this._peerConnection.localDescription?.type, 'answer', 'onmessage SLD worked');

      this.onDescription(this.peerId, this._peerConnection.localDescription);
    } else {
      assert_equals(this._peerConnection.remoteDescription?.type, 'answer', 'Answer was set');
      assert_equals(this._peerConnection.signalingState, 'stable', 'answered');

      this._peerConnection.dispatchEvent(new Event('negotiated'));
    }
  }

  public addStream(stream?: MediaStream): void {
    if (!stream) {
      this._remoteStream.getTracks().forEach(track => track.stop());
      return;
    }

    stream.getTracks()
      .forEach(track => this._peerConnection.addTrack(track, stream));

    stream.getVideoTracks().forEach(track => {
      // console.log(RTCRtpSender.getCapabilities('video'));

      this._peerConnection
        .getTransceivers()
        .find(transceiver => transceiver.sender && transceiver.sender.track === track)
        ?.setCodecPreferences([
          { clockRate: 90000, mimeType: 'video/VP8' } // TODO Get this dynamically - where mimeType === video/VP8
        ]);
    });
  }

  public async replaceStream(stream: MediaStream): Promise<void> {
    stream.getTracks().forEach(track => {
      console.log(track.enabled);
      const sender = this._peerConnection.getSenders().find(s => s.track?.kind === track.kind);

      sender?.replaceTrack(track);
    });
  }

  public end(bubble: boolean): void {
    // this._resizeDataChannel.close();

    this._remoteStream
      .getTracks()
      .forEach(track => track.stop());

    this._peerConnection.close();

    if (bubble) {
      this.onEnd(this.peerId);
    }
  }

  public notifySizeChanged(): void {
    const msg: ResizeMessage = this.getContainerSize(this.peerId);

    if (this._resizeDataChannel.readyState === 'open') {
      this._resizeDataChannel.send(JSON.stringify(msg));
      console.log('sent resize msg:', msg);

      this._sentSize = true;
    }
  }
}
