import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { map, Subject, takeUntil } from 'rxjs';
import { FocusableComponent } from '../focusable/focusable.component';

@Component({
  template: '',
  selector: 'sv-focus-root'
})
export abstract class FocusRootComponent extends FocusableComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly _keyboardEvents = new Subject<KeyboardEvent>();

  public ngOnInit(): void {
    this._keyboardEvents.asObservable()
      .pipe(
        takeUntil(this.onDestroy$),
        map(event => this.handleKeyPress(event))
      ).subscribe();
  }

  public ngAfterViewInit(): void {
    this.setFocus(true);
    this.changeDetectorRef.detectChanges();
  }

  public override ngOnDestroy(): void {
    this._keyboardEvents.complete();

    super.ngOnDestroy();
  }

  // public override handleKeyPress(event: KeyboardEvent): boolean {
  //   const keyPressHandled = this.handleChildKeyPress(event);
  //
  //   if (keyPressHandled) {
  //     return true;
  //   }
  //
  //   switch (event.key) {
  //     case 'ArrowLeft':
  //     case 'ArrowUp':
  //       this.decrementChildFocus();
  //       break;
  //
  //     case 'ArrowRight':
  //     case 'ArrowDown':
  //       this.incrementChildFocus();
  //       break;
  //
  //     default:
  //       return false;
  //   }
  //
  //   return true;
  // }

  @HostListener('window:keyup', ['$event'])
  private onKeyboardEvent(event: KeyboardEvent): void {
    this.changeDetectorRef.detach();

    this._keyboardEvents.next(event);

    this.changeDetectorRef.reattach();
    this.changeDetectorRef.markForCheck();
  }
}
