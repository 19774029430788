import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CLINIC_CONFIG_THEME_SERVICES } from '@shareview/clinic/config';
import { SharedComponentsModule } from '@shareview/shared/components';
import { SharedFocusableModule } from '@shareview/shared/focusable';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { QrCodeModule } from 'ng-qrcode';
import { HomeContentComponent } from './components/home-content/home-content.component';
import { LabelColumnComponent } from './components/label-column/label-column.component';
import { QrCodeCardComponent } from './components/qr-code-card/qr-code-card.component';
import { VideoLabelComponent } from './components/video-label/video-label.component';
import { VideoLibraryComponent } from './components/video-library/video-library.component';
import { VideoRowComponent } from './components/video-row/video-row.component';
import { VideoTileComponent } from './components/video-tile/video-tile.component';
import { CategoryVideosLabelPipe } from './pipes/category-videos-label.pipe';
import { ClinicHeroConfigThemeService } from './services/clinic-hero-config-theme.service';
import { ClinicStylesConfigThemeService } from './services/clinic-styles-config-theme.service';
import { VideoLibraryCategoryConfigThemeService } from './services/video-library-category-config-theme.service';
import { VideoLibraryConfigThemeService } from './services/video-library-config-theme.service';
import { VideoLibraryTileConfigThemeService } from './services/video-library-tile-config-theme.service';

@NgModule({
  declarations: [
    LabelColumnComponent,
    VideoLabelComponent,
    VideoRowComponent,
    VideoTileComponent,
    CategoryVideosLabelPipe,
    QrCodeCardComponent,
    VideoLibraryComponent,
    HomeContentComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    SharedFocusableModule,
    MatProgressSpinnerModule,
    SharedComponentsModule,
    InlineSVGModule,
    QrCodeModule
  ],
  exports: [
    LabelColumnComponent,
    VideoLabelComponent,
    VideoRowComponent,
    VideoTileComponent,
    QrCodeCardComponent,
    VideoLibraryComponent,
    HomeContentComponent
  ]
})
export class ClinicComponentsModule {

  public static forRoot(): ModuleWithProviders<ClinicComponentsModule> {
    return {
      ngModule: ClinicComponentsModule,
      providers: [
        { provide: CLINIC_CONFIG_THEME_SERVICES, useClass: ClinicHeroConfigThemeService, multi: true },
        { provide: CLINIC_CONFIG_THEME_SERVICES, useClass: ClinicStylesConfigThemeService, multi: true },
        { provide: CLINIC_CONFIG_THEME_SERVICES, useClass: VideoLibraryCategoryConfigThemeService, multi: true },
        { provide: CLINIC_CONFIG_THEME_SERVICES, useClass: VideoLibraryConfigThemeService, multi: true },
        { provide: CLINIC_CONFIG_THEME_SERVICES, useClass: VideoLibraryTileConfigThemeService, multi: true }
      ]
    };
  }
}
