<div #container fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div [@indicator]="loaded"
         [class.hidden]="!(scroll.canScrollBack && loaded)"
         class="scroll-indicator"
         inlineSVG="/assets/arrow_back_ios_black_48dp.svg">
    </div>
  </div>

  <sv-scrollable-focusable #scroll [disabled]="!loaded" class="scroll" fxFlex>
    <div [fxLayoutGap]="cardGap" fxFlex fxLayout="row" svScrollableViewport>
      <ng-container *ngFor="let item of items; index as i; trackBy: trackByCategoryVideo">
        <sv-video-tile [@card]="" [item]="item"></sv-video-tile>
      </ng-container>
    </div>
  </sv-scrollable-focusable>

  <div fxLayout="column" fxLayoutAlign="center center">
    <div [@indicator]="loaded"
         [class.hidden]="!(scroll.canScrollForward && loaded)"
         class="scroll-indicator"
         inlineSVG="assets/arrow_forward_ios_black_48dp.svg">
    </div>
  </div>
</div>

<div *ngIf="!loaded"
     [@spinner]="loaded"
     [style.bottom]="0"
     [style.left]="0"
     [style.right]="0"
     [style.top]="container.offsetTop + 'px'"
     class="spinner-container"
     fxLayout="row"
     fxLayoutAlign="center center">

  <mat-spinner color="accent"></mat-spinner>
</div>
