import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  BrandHeaderComponent,
  ColorRingsLayoutComponent,
  ExperienceBrandComponent
} from '@shareview/shared/components';
import { QrCodeModule } from 'ng-qrcode';

@Component({
  selector: 'sv-personalization-page',
  standalone: true,
  imports: [CommonModule, ExperienceBrandComponent, BrandHeaderComponent, ColorRingsLayoutComponent, QrCodeModule, MatIconModule],
  templateUrl: './personalization-page.component.html',
  styleUrls: ['./personalization-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalizationPageComponent {
}
