import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@shareview/shared/components';
import { SessionManager } from '@shareview/tele-engine';
import { filter, firstValueFrom, from, map, switchMap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  RtcMessage,
  SignalingService,
  UserHelloMessage,
  UserJoinMessage,
  UserLeaveMessage
} from './webrtc/signaling/signaling.service';
import { Peer2, ResizeMessage } from './webrtc/webrtc/peer2';

const CHANNEL_NAME = 'webrtc-demo';

@Component({
  selector: 'sv-video-call-webrtc',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './video-call-webrtc.component.html',
  styleUrls: ['./video-call-webrtc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoCallWebRtcComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container', { static: true, read: ElementRef })
  private readonly container!: ElementRef<HTMLDivElement>;

  @ViewChild('self', { static: true })
  private _selfVideo!: ElementRef<HTMLDivElement>;

  private readonly channelId = 'webrtc-hospital-demo';
  private readonly sessionManager = new SessionManager(this.channelId);

  public ngOnInit(): void {
  }

  public async ngAfterViewInit(): Promise<void> {
    await this.sessionManager.connect(this.container.nativeElement, this._selfVideo.nativeElement);

  }

  @HostListener('window:beforeunload')
  public override async ngOnDestroy(): Promise<void> {
    this.sessionManager.disconnect().then();

    super.ngOnDestroy();
  }

  // @ViewChild('self', { static: true })
  // private _selfVideo!: ElementRef<HTMLVideoElement>;
  //
  // @ViewChild('remote', { static: true })
  // private _remoteVideo!: ElementRef<HTMLVideoElement>;
  //
  // @ViewChild('content', { static: true })
  // private _content!: ElementRef<HTMLDivElement>;
  //
  // private readonly _userId: string;
  // private _localStream?: MediaStream;
  //
  // private _peers: Peer2[] = [];
  //
  // public constructor(private signalingService: SignalingService, private httpClient: HttpClient, private route: ActivatedRoute) {
  //   super();
  //
  //   this._userId = uuidv4();
  // }
  //
  // public ngOnInit(): void {
  //   this.subscription = this.signalingService.join$.subscribe(e => this.userJoin(e));
  //   this.subscription = this.signalingService.hello$.subscribe(e => this.userHello(e));
  //   this.subscription = this.signalingService.leave$.subscribe(e => this.userLeave(e));
  //   this.subscription = this.signalingService.rtc$.subscribe(e => this.handleRtc(e));
  // }
  //
  // public ngAfterViewInit(): void {
  //   this.signalingService.connect()
  //     .then(() => navigator.mediaDevices.getUserMedia({
  //       audio: {
  //         channelCount: { ideal: 1 },
  //         echoCancellation: { ideal: true }
  //       },
  //       video: {
  //         width: { max: 1920 },
  //         height: { max: 1080 }
  //       }
  //     }))
  //     .then(stream => {
  //       // stream.getVideoTracks().forEach(track => track.enabled = false);
  //
  //       this._localStream = stream;
  //       this._selfVideo.nativeElement.srcObject = stream;
  //       this._selfVideo.nativeElement.muted = true;
  //
  //       console.log('joining channel', CHANNEL_NAME, this._userId)
  //
  //       return this.signalingService.join(CHANNEL_NAME, this._userId);
  //     }).then(() => {
  //       const sessionId = this.route.snapshot.paramMap.get('sessionId');
  //       const request = this.httpClient.post(`https://videocall.services.shareview.dev/v1/calls/queue/${sessionId}`, {});
  //
  //       return firstValueFrom(request);
  //   });
  // }
  //
  // @HostListener('window:beforeunload', ['$event'])
  // public override async ngOnDestroy(): Promise<void> {
  //   await this.endCall();
  //
  //   super.ngOnDestroy();
  // }
  //
  // private getPeerById(peerId: string): Peer2 {
  //   let peer = this._peers.find(peer => peer.userId === peerId);
  //
  //   if (!peer) {
  //     peer = this.createPeer(peerId, false);
  //   }
  //
  //   return peer;
  // }
  //
  // private createPeer(peerId: string, polite: boolean): Peer2 {
  //   let peer = this._peers.find(peer => peer.userId === peerId);
  //
  //   if (peer) {
  //     return peer;
  //   }
  //
  //   peer = new Peer2(peerId, polite, this._content);
  //
  //   this.subscription = peer.ice$
  //     .pipe(
  //       map(ice => this.signalingService.rtc(CHANNEL_NAME, this._userId, peerId, ice)),
  //       switchMap(promise => from(promise))
  //     ).subscribe();
  //
  //   this.subscription = peer.description$
  //     .pipe(
  //       map(description => this.signalingService.rtc(CHANNEL_NAME, this._userId, peerId, undefined, description)),
  //       switchMap(promise => from(promise))
  //     ).subscribe();
  //
  //   this.subscription = peer.end$
  //     .pipe(
  //       map(() => this._peers.findIndex(peer => peer.userId === peerId)),
  //       filter(index => index >= 0)
  //     ).subscribe(index => {
  //       this._peers.splice(index, 1);
  //       this._remoteVideo.nativeElement.srcObject = null;
  //     });
  //
  //   this.subscription = peer.resize$
  //     .pipe(
  //       map(msg => this.resizeVideo(msg)),
  //       switchMap(promise => from(promise))
  //     ).subscribe();
  //
  //   this._remoteVideo.nativeElement.srcObject = peer.remoteStream;
  //   this._peers.push(peer);
  //
  //   return peer;
  // }
  //
  // private async resizeVideo(msg: ResizeMessage): Promise<void> {
  //   this._localStream?.getTracks().forEach(track => track.stop());
  //
  //   const stream = await navigator.mediaDevices.getUserMedia({
  //     audio: {
  //       channelCount: { ideal: 1 },
  //       echoCancellation: { ideal: true }
  //     },
  //     video: {
  //       aspectRatio: msg.width / msg.height,
  //       // aspectRatio: Math.round(msg.width / msg.height * 100) / 100
  //       width: { max: 1280 },
  //       height: { max: 720 }
  //     }
  //   });
  //
  //   this._selfVideo.nativeElement.srcObject = stream;
  //
  //   for (const peer of this._peers) {
  //     await peer.replaceStream(stream);
  //   }
  // }
  //
  // private userJoin({ userId }: UserJoinMessage): void {
  //
  //   this.signalingService
  //     .hello(CHANNEL_NAME, this._userId, userId)
  //     .then(() => {
  //       this.createPeer(userId, false);
  //       this.getPeerById(userId)?.addStream(this._localStream);
  //     });
  // }
  //
  // private userHello({ fromUserId }: UserHelloMessage): void {
  //   this.createPeer(fromUserId, true);
  //   this.getPeerById(fromUserId)?.addStream(this._localStream);
  // }
  //
  // private userLeave({ userId }: UserLeaveMessage): void {
  //   this.getPeerById(userId)?.end();
  // }
  //
  // private handleRtc({ fromUserId, candidate, description }: RtcMessage): void {
  //   if (candidate) {
  //     this.getPeerById(fromUserId)?.handleIce(JSON.parse(candidate));
  //   }
  //
  //   if (description) {
  //     this.getPeerById(fromUserId)?.handleDescription(JSON.parse(description));
  //   }
  // }
  //
  // public async endCall(): Promise<void> {
  //   await this.signalingService.leave(CHANNEL_NAME, this._userId);
  //
  //   if (this._localStream) {
  //     this._localStream.getTracks().forEach(track => track.stop());
  //     this._localStream = undefined;
  //   }
  //
  //   this._peers.forEach(peer => peer.end());
  //   this._peers = [];
  // }
}
