import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ImageTextComponentStyle } from './image-text.component.style';

@Component({
  selector: 'sv-image-text',
  templateUrl: './image-text.component.html',
  styleUrls: ['./image-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageTextComponent extends ImageTextComponentStyle {

  public get imageUrl(): string | undefined {
    return this.value?.imageUrl;
  }

  public get text(): string {
    return this.value?.text ?? '';
  }
}
