import { HslColorValue } from '../interfaces/hsl-color-value';
import { CssColorFilterByHslSolver } from './css-color-filter-by-hsl-solver';

export class Color {
  private _red: number;
  private _green: number;
  private _blue: number;

  public constructor(r: number, g: number, b: number) {
    this._red = this.clamp(r);
    this._green = this.clamp(g);
    this._blue = this.clamp(b);
  }

  public get r(): number {
    return this._red;
  }

  public get g(): number {
    return this._green;
  }

  public get b(): number {
    return this._blue;
  }

  public static fromHexString(hex: string): Color {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result) {
      throw new Error(`Could not parse hex color value: ${hex}`);
    }

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return new Color(r, g, b);
  }

  public toCssFilter(): { values: number[] | null, loss: number, filter: string } {
    const solver = new CssColorFilterByHslSolver(this);

    return solver.solve();
  }

  public toString(): string {
    return `rgb(${Math.round(this._red)}, ${Math.round(this._green)}, ${Math.round(this._blue)})`;
  }

  public set(r: number, g: number, b: number): void {
    this._red = this.clamp(r);
    this._green = this.clamp(g);
    this._blue = this.clamp(b);
  }

  public hueRotate(angle = 0): void {
    angle = angle / 180 * Math.PI;

    const sin = Math.sin(angle);
    const cos = Math.cos(angle);

    this.multiply([
      0.213 + cos * 0.787 - sin * 0.213,
      0.715 - cos * 0.715 - sin * 0.715,
      0.072 - cos * 0.072 + sin * 0.928,
      0.213 - cos * 0.213 + sin * 0.143,
      0.715 + cos * 0.285 + sin * 0.140,
      0.072 - cos * 0.072 - sin * 0.283,
      0.213 - cos * 0.213 - sin * 0.787,
      0.715 - cos * 0.715 + sin * 0.715,
      0.072 + cos * 0.928 + sin * 0.072
    ]);
  }

  public grayscale(value = 1): void {
    this.multiply([
      0.2126 + 0.7874 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 + 0.2848 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 + 0.9278 * (1 - value)
    ]);
  }

  public sepia(value = 1): void {
    this.multiply([
      0.393 + 0.607 * (1 - value),
      0.769 - 0.769 * (1 - value),
      0.189 - 0.189 * (1 - value),
      0.349 - 0.349 * (1 - value),
      0.686 + 0.314 * (1 - value),
      0.168 - 0.168 * (1 - value),
      0.272 - 0.272 * (1 - value),
      0.534 - 0.534 * (1 - value),
      0.131 + 0.869 * (1 - value)
    ]);
  }

  public saturate(value = 1): void {
    this.multiply([
      0.213 + 0.787 * value,
      0.715 - 0.715 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 + 0.285 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 - 0.715 * value,
      0.072 + 0.928 * value
    ]);
  }

  public multiply(matrix: number[]): void {
    const newR = this.clamp(this._red * matrix[0] + this._green * matrix[1] + this._blue * matrix[2]);
    const newG = this.clamp(this._red * matrix[3] + this._green * matrix[4] + this._blue * matrix[5]);
    const newB = this.clamp(this._red * matrix[6] + this._green * matrix[7] + this._blue * matrix[8]);
    this._red = newR;
    this._green = newG;
    this._blue = newB;
  }

  public brightness(value = 1): void {
    this.linear(value);
  }

  public contrast(value = 1): void {
    this.linear(value, -(0.5 * value) + 0.5);
  }

  public linear(slope = 1, intercept = 0): void {
    this._red = this.clamp(this._red * slope + intercept * 255);
    this._green = this.clamp(this._green * slope + intercept * 255);
    this._blue = this.clamp(this._blue * slope + intercept * 255);
  }

  public invert(value = 1): void {
    this._red = this.clamp((value + this._red / 255 * (1 - 2 * value)) * 255);
    this._green = this.clamp((value + this._green / 255 * (1 - 2 * value)) * 255);
    this._blue = this.clamp((value + this._blue / 255 * (1 - 2 * value)) * 255);
  }

  public hsl(): HslColorValue {
    // Code taken from https://stackoverflow.com/a/9493060/2688027, licensed under CC BY-SA.
    const r = this._red / 255;
    const g = this._green / 255;
    const b = this._blue / 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    const l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;

      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;

        case g:
          h = (b - r) / d + 2;
          break;

        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }

    return {
      h: h * 100,
      s: s * 100,
      l: l * 100
    };
  }

  public clamp(value: number): number {
    if (value > 255) {
      value = 255;
    } else if (value < 0) {
      value = 0;
    }
    return value;
  }
}

