import { Injectable } from '@angular/core';
import { ClinicConfig, ClinicConfigThemeService, StyleVars } from '@shareview/clinic/config';
import { Color } from '@shareview/shared/data-models';

@Injectable()
export class VideoLibraryConfigThemeService extends ClinicConfigThemeService {

  public applyThemeConfig(config: ClinicConfig | null): StyleVars {
    const videoLibraryConfig = config?.home?.videoLibrary;
    const scrollIndicatorColorFilter = videoLibraryConfig?.scrollIndicatorColor
      ? Color.fromHexString(videoLibraryConfig?.scrollIndicatorColor).toCssFilter()
      : undefined;

    return [
      this.style('--clinic-video-library-scroll-indicator-color-filter', scrollIndicatorColorFilter?.filter)
    ];
  }
}
