import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ClinicVideoPlayerOverlayService } from './services/clinic-video-player-overlay.service';

@NgModule({
  imports: [CommonModule]
})
export class ClinicServicesModule {

  public static forRoot(): ModuleWithProviders<ClinicServicesModule> {
    return {
      ngModule: ClinicServicesModule,
      providers: [ClinicVideoPlayerOverlayService]
    };
  }
}
